var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "NavDesktop" },
    [
      _c("router-link", {
        staticClass: "NavDesktop__logo",
        attrs: { to: { name: "home" }, tag: "a" }
      }),
      _vm._l(_vm.componentNavItems, function(route, index) {
        return _c(
          "div",
          {
            key: index,
            on: {
              click: function($event) {
                return _vm.openRoute(index)
              }
            }
          },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "NavDesktop__route row row--align-center fs-14 fw-med uppercase",
                attrs: {
                  to: route.to,
                  id: "Testing__" + route.title.replace(/ /g, ""),
                  "active-class": "NavDesktop__route--selected fc-blue",
                  tag: "a"
                }
              },
              [
                _c("i", { class: "fa " + route.icon + " fa-28 fa-3 fa--none" }),
                _vm._v("\n      " + _vm._s(route.title) + "\n    ")
              ]
            ),
            _c("transition", { attrs: { name: "NavDesktop__fade" } }, [
              route.subroutes && route.expanded
                ? _c(
                    "div",
                    { staticClass: "NavDesktop__subroutes" },
                    _vm._l(route.subroutes, function(subroute, subrouteIndex) {
                      return _c(
                        "router-link",
                        {
                          key: subrouteIndex,
                          staticClass: "NavDesktop__subroute fs-14 capitalize",
                          attrs: {
                            to: subroute.to,
                            "active-class": "NavDesktop__subroute--selected",
                            tag: "a"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(subroute.title) +
                              "\n        "
                          )
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e()
            ])
          ],
          1
        )
      }),
      _c(
        "router-link",
        {
          staticClass:
            "NavDesktop__route NavDesktop__route-logout row row--width-auto fc-light fs-14",
          attrs: { to: { name: "log-out" }, tag: "a" }
        },
        [_vm._v("\n    LOG OUT\n  ")]
      ),
      _vm.isMaster
        ? _c(
            "router-link",
            {
              staticClass:
                "NavDesktop__route NavDesktop__route-release-notes row row--width-auto fc-light fs-14",
              attrs: { to: { name: "release-notes" }, tag: "a" }
            },
            [_vm._v("\n    RELEASE NOTES\n  ")]
          )
        : _vm._e(),
      _vm.isMaster
        ? _c(
            "router-link",
            {
              staticClass:
                "NavDesktop__route NavDesktop__route-styleguide row row--width-auto fc-light fs-14",
              attrs: { to: { name: "styleguide" }, tag: "a" }
            },
            [_vm._v("\n    STYLEGUIDE\n  ")]
          )
        : _vm._e(),
      _vm.userIsClient && _vm.accountManager
        ? _c(
            "div",
            { staticClass: "NavDesktop__account-manager bg-white column" },
            [
              _c(
                "label",
                { staticClass: "fw-med fc-mid fs-14 mb-8 no-select no-wrap" },
                [_vm._v("\n      YOUR ACCOUNT REP:\n    ")]
              ),
              _c("label", { staticClass: "fc-dark fs-12 mb-4 no-select" }, [
                _c("i", { staticClass: "fa fa-user fc-light" }),
                _vm._v(
                  _vm._s(
                    _vm.accountManager.first_name.trim() +
                      " " +
                      _vm.accountManager.last_name.trim()
                  ) + "\n    "
                )
              ]),
              _c(
                "a",
                {
                  staticClass:
                    "fc-blue fs-12 mb-4 row row--align-center no-wrap",
                  attrs: { href: "mailto:" + _vm.accountManager.user.email }
                },
                [
                  _c("i", { staticClass: "fa fa-mail fc-light" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.accountManager.user.email) +
                      "\n    "
                  )
                ]
              ),
              _c(
                "label",
                { staticClass: "fc-dark fs-12 row row--align-center no-wrap" },
                [
                  _c("i", { staticClass: "fa fa-phone" }),
                  _vm._v(
                    "\n      (410) 204-2084 " +
                      _vm._s(
                        _vm.accountManager.extension
                          ? "ext " + _vm.accountManager.extension
                          : ""
                      ) +
                      "\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }