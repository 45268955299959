/* eslint-disable no-plusplus */
/* eslint-disable no-multiple-empty-lines */
import qs from 'qs'
import moment from 'moment-timezone'
// Helpers
import bobtailAPI from './axiosConfig'
import {
  ClientDocument,
  Invoice
} from './api'
import { captureSentryEvent } from './sentryMixin'
import {
  CError,
  CLog
} from './consoleMixin'

// when first transfer goes out
const firstAchHours = 13
const firstAchTime = '1pm'
// second transfer
const secondAchHours = 20
const secondAchTime = '8pm'
// cutoff for transfer buckets
const firstAchCutoffHours = 11
const secondAchCutoffHours = 19

export {
  firstAchCutoffHours,
  firstAchHours,
  firstAchTime,
  secondAchHours,
  secondAchTime
}

export const debounce = (func, wait, immediate = null) => {
  let timeout;
  return () => {
    const context = this
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context);
  };
}


// DATE AND TIME, TEXT
export const transferBucketTime = () => {
  // eslint-disable-next-line max-len
  moment.tz.add('America/New_York|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6')
  const currentTimeInNYC = moment().tz('America/New_York')
  CLog(`%c Current time in NYC: ${currentTimeInNYC}`, 'color: green; font-size: 16px;')
  // If between today 1PM and today 7PM, return '8pm'
  const hourInNY = currentTimeInNYC.get('hour')
  if (hourInNY >= firstAchHours && hourInNY <= secondAchCutoffHours) {
    return secondAchTime
  }
  return firstAchTime
}

export const sanitizeAlgoliaNames = (name) => {
  const regex = /(<em>)*(<\/em>)*/gi
  // if name is null do not throw an error
  return name && name.replace(regex, '')
}

// Unused, but foreseeable in the future
export const formatAddressCamelCase = (address, address_2 = null, city, state, zip) => {
  const completeAddress = `${address}${address_2 ? `, ${address_2}` : ''}, ${city}, ${state} ${zip}`.toLowerCase()

  // uppercase first char and any char immediately following a space
  let capitalizeNext = false
  const formattedAddress = Array.from(completeAddress.trim()).map((char, index) => {
    let transformedChar = char

    // Capitalize first index in the address
    if (index === 0) transformedChar = char.toUpperCase()

    // If the previous char was ' ' or '.'
    if (capitalizeNext) {
      capitalizeNext = false
      transformedChar = char.toUpperCase()
    }

    // If the current char is ' ' or '.', capitalize the next char
    if ([' ', '.'].includes(char)) capitalizeNext = true

    return transformedChar
  }).join('')
  return formattedAddress
}

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return null
  const cleaned = (phoneNumberString.trim()).replace(/\D/g, '')
  const match = cleaned.match(/^(1?)(\d{3})(\d{3})(\d{4})(\d+)*$/)
  if (match) {
    let formattedNumber = `${match[2]}-${match[3]}-${match[4]}`
    if (match[5] !== undefined) formattedNumber += ` x${match[5]}`
    return formattedNumber
  }
  return null
}

export const formatStringCamelCase = (string) => {
  if (!string) return null
  // uppercase first char and any char immediately following a space
  let capitalizeNext = false
  const formattedString = Array.from(string.trim().toLowerCase()).map((char, index) => {
    let transformedChar = char
    // Capitalize first index in the address
    if (index === 0) transformedChar = char.toUpperCase()
    // If the previous char was ' ' or '.'
    if (capitalizeNext) {
      capitalizeNext = false
      transformedChar = char.toUpperCase()
    }
    // If the current char is ' ' or '.', capitalize the next char
    if ([' ', '.'].includes(char)) capitalizeNext = true
    return transformedChar
  }).join('')
  return formattedString
}

export const transformStringNumberToNumber = (expression, options = { pennies: true, round: true }) => {
  const adj = expression || expression === 0 ? expression.toString() : null
  /*
   * Possible inputs (theoretically, after validation)
   * -------------------------------------------------
   * Validation: Does not allow letters, spaces, or
   * special characters (blacklisted ',' + isFloat)
   *
   * 1. null                  => null
   * 2. ''                    => null
   * 3. '100'                 => Number(x)
   * 4. '100.11'              => Number(x)
   * 5. '1001.11'             => Number(x)
   * 6. '1,111.11'            => Number(x.replaceAll(',', ''))
   * 7. '20,123,123.11'       => Number(x.replaceAll(',', ''))
   * 8. '1,2,33.11'           => Number(x.replaceAll(',', ''))
   * 9. '1111.1111'           => Number(x)
   * 10. 1.1234               => Number(x)
   * 11. 11.1234              => Number(x)
   * 12. 1234.1234            => Number(x)
   * 13. '582.06'             => Number(x)
   * 14. '-57'                => Number(x)
   * 15. 0                    => Number(x)
   * 16. '0'                  => Number(x)
   */

  // #1 or #2
  if ((!adj || !adj.length) && adj !== 0) return null

  const regex = /,/g
  let value = Number(adj.replace(regex, ''))

  if (options.pennies) value *= 100

  // Math.round returns the nearest integer (works both low and high)
  if (options.round) return Math.round(value)

  return value
}



// CSS MANIPULATION
// Mutates a DOM element, should be A-okay since it's not data
// Turns pixel heights into rems
export const setWrapperHeight = (el) => {
  const wrapper = document.querySelector(el)
  wrapper.style.height = null // reset
  wrapper.style.height = `${wrapper.clientHeight / 16}rem`
}



// DATA
// optionalAppendedFilterString allows us to append complex combinations of AND, OR, NOT to
// the end of our filter string for Algoila, because this function isn't that robust
export const buildAlgoliaFilterString = (filters, optionalAppendedFilterString = ' ') => {
  const keys = Object.keys(filters)
  const filteredKeys = keys.filter(key => {
    // If key value is not null, an empty string, or is an array && has no length, keep it
    const value = filters[key]

    return (
      value !== null
      && value !== ''
      && !(Array.isArray(value) && !value.length)
    )
  })
  // If the value is NOT an array, join key and value with ":"
  // If value is an array, join array
  const algoliaFilterString = filteredKeys
    .map(key => {
      // accept multiple possible values for a field
      if (key.includes('or___')) {
        const keyName = key.replace('or___', '')
        return `(${keyName}:${filters[key].join(` OR ${keyName}:`)})`
      }

      // Key value is an array
      // Algolia filters: https://www.algolia.com/doc/api-reference/api-parameters/filters
      // Specifically for days outstanding from/to in approved-search
      if (Array.isArray(filters[key])) {
        return filters[key].length > 1
          ? `(${filters[key].join(' AND ')})`
          : filters[key][0]
      }
      // Key value is not an array
      return [key, filters[key]].join(':')
    })
    .join(' AND ')
    .concat(optionalAppendedFilterString)
  CLog(`%c Algolia filter string: ||${algoliaFilterString}||`, 'color: blue;')
  return algoliaFilterString
}

// Handles deep objects via qs
export const readQueryStringToObject = (queryString) => {
  return qs.parse(queryString)
}

export const stringifyObjectToQueryString = (obj) => {
  // Remove null/empty values
  const cleanedObj = {}
  Object.keys(obj).forEach(key => {
    if (
      obj[key] === ''
      || obj[key] === null
      || obj[key] === undefined
      || obj[key] === false
    ) {
      return
    }
    cleanedObj[key] = obj[key]
  })
  // Meant for production (logs filters for paid and declined search)
  return qs.stringify(cleanedObj)
}

// replace links to duplicate invoices with html so the user never sees {{{loadnumber:id}}}
const transformInvoiceUpdates = (updates) => {
  if (!updates) return null
  const invoice_updates = [...updates]
  invoice_updates.forEach(update => {
    // Duplicate links in individual updates: https://github.com/fs-bobtail/bobtail/pull/802
    if (update.metadata && update.metadata.possible_duplicates) {
      update.duplicate_links = []
      update.metadata.possible_duplicates.forEach((dupe, index) => {
        update.duplicate_links.push({
          comma: index !== update.metadata.possible_duplicates.length - 1,
          invoice_id: dupe.id,
          load_number: dupe.load_number,
          status: dupe.status,
        })
      })
    }
    // for nonpayments the update_status & notes should be displayed in reverse
    // https://github.com/fs-bobtail/bobtail/pull/856
    if (update.update_type === 'nonpayment') {
      update.notes = update.update_status.replace('.', '').charAt(0).toUpperCase()
        + update.update_status.slice(1)
      update.update_status = 'Nonpayment'
    }
  })
  return invoice_updates
}

/*
 * Why we transform:
 * 1. Simplifies JS in component templates, mostly by avoiding tertiary statements and diving
 *    numbers by 100
 * 2. base-invoice-create requires specifically formatted props to hydrate the component
 * 3. B.E doesn't delivery user-friendly amounts, so everytime an amount would be needed, the
 *    F.E. would have to divide by 100
 * 4. The B.E. uses poor property naming
 * 5. B.E. doesn't deliver certain calculated values, such as daysApproved, overpaid, shortpaid
 * 6. B.E. is not consistent on a property's existence or value when no value exists for said prop
 *    which means it is sometimes missing on the invoice object, null, '', or false
 * 7. Invoice updates need to be formatted for certain use cases, such as duplicate links
 */
export const transformRawInvoice = (invoice) => {
  // If invoice has already been trasnformed, skip
  if (invoice.transformed) {
    return invoice
  }
  const totalAmount = Number((Number.parseFloat(invoice.total_amount) / 100).toFixed(2))
  let overpaid = null
  let shortpaid = null
  if (invoice.debtor_payment && invoice.debtor_payment.length) {
    const totalDebtorPaid = invoice.debtor_payment.reduce((acc, cur) => acc + (cur.amount / 100), 0)
    overpaid = totalDebtorPaid > totalAmount
      ? (totalDebtorPaid - totalAmount)
      : null
    shortpaid = totalDebtorPaid < totalAmount
      ? (totalAmount - totalDebtorPaid)
      : null
  }
  const last_flag_message = [...new Set((invoice.last_flag_message || '').split(', '))]
    .join(', ')

  const daysApproved = invoice.is_buyout
    ? (moment().startOf('day')).diff(moment(invoice.original_buyout_invoice_date).startOf('day'), 'days', true)
    : (moment().startOf('day')).diff(moment(invoice.approved_date).startOf('day'), 'days')
  return {
    ...invoice,
    // BE delivered amounts are integers, which means we'd have to do the below
    // JavaScript EVERYWHERE an amount is displayed or set as the input, which
    // would be a lot of bloated code, and be very unfriendly to maintain
    amounts: {
      advance: invoice.advance ? (invoice.advance / 100).toFixed(2) : 0,
      client_total_to_pay_from_invoice: invoice.client_total_to_pay_from_invoice
        ? (invoice.client_total_to_pay_from_invoice / 100).toFixed(2)
        : 0,
      detention: invoice.detention ? (invoice.detention / 100).toFixed(2) : 0,
      lumper: invoice.lumper ? (invoice.lumper / 100).toFixed(2) : 0,
      overpaid,
      primary_rate: invoice.primary_rate ? (invoice.primary_rate / 100).toFixed(2) : 0,
      shortpaid,
      total: totalAmount,
    },
    // We only care about how many days since approved, not when an invoice was approved
    days_approved: daysApproved,
    // Backend doesn't ALWAYS delivery this property on the invoice
    debtor_payment: invoice.debtor_payment ? invoice.debtor_payment : null,
    // Backend doesn't ALWAYS delivery this property on the invoice
    first_debtor_payment: invoice.first_debtor_payment ? invoice.first_debtor_payment : null,
    // algolia isn't always quick to deliver the correct value
    last_flag_message,
    // Backend doesn't ALWAYS delivery this property on the invoice
    original_buyout_invoice_date: invoice.original_buyout_invoice_date
      ? new Date(invoice.original_buyout_invoice_date)
      : null,
    // Backend doesn't ALWAYS delivery this property on the invoice
    paid_date: invoice.paid_date ? invoice.paid_date : null,
    // Used on frontend to avoid transforming an already trasnformed invoice
    transformed: true,
    updates: transformInvoiceUpdates(invoice.invoice_updates),
  }
}

// USER
export const storeUserInLocalStorage = async () => {
  // Set JWT token in localStorage so bobtailAPI will have it for BASE_API()
  try {
    const userInfo = (await (await bobtailAPI()).get('/api/users/me')).data
    CLog(userInfo, JSON.stringify(userInfo))
    window.localStorage.setItem('user', JSON.stringify(userInfo))
    CLog(
      '%c User stored in localStorage',
      'color: green;font-size: 18px; font-weight: 600;'
    )
  } catch (error) {
    captureSentryEvent(
      'Helpers: storeUserInLocalStorage',
      {
        config: error.config,
        details: error,
        response: error.response,
        message: 'Most likely due to user login being valid with Cognito but not existing in our DB',
      }
    )
    CLog('storedIn', error)
    // Don't let user go anywhere or see anything if invalid login
    // Throwing an error so the try block's catch block in login.vue.onSuccess() fires
    throw new Error('User is not in database')
  }
}

// NAVIGATION
export const getNextInvoicePosition = async (id, direction = null) => {
  // Returns the existence in form of an invoice's position in the array of ids from search

  // Each time we go to get the invoice's position, we should update our stored invoice IDs
  // in local storage by re-searching
  const lsSearch = JSON.parse(localStorage.getItem('search'))
  // When manually routing to an invoice-details page, search in LS is null
  if (!lsSearch) {
    return 'search-is-null'
  }

  const search = (await Invoice.queryList(lsSearch.params, 1)).data // Returning 1 === faster
  const invoiceIDs = search.all_invoice_ids

  // Update local storage
  // Store position in case we need to reference our last known position
  localStorage.setItem('search', JSON.stringify({
    ...lsSearch,
    ids: invoiceIDs,
  }))

  CLog(`ID looking for: ${id}`)
  const invoicePosition = invoiceIDs.indexOf(id)
  CLog(`Invoice position: ${invoicePosition}`)

  /*
   * IF IDs have length:
   *   Is there a match in IDs and ID?
   *     NO: Load the invoice at the last known position
   *     YES: Are we going backward or forward?
   *       BACKWARD: Load the ID 1 index backward from the ID match
   *       FORWARD: Load the ID 1 index forward from the ID match
   * ELSE
   *   Load the passed in ID
   */

  // If IDs has length
  if (invoiceIDs.length) {
    // If there is a match in IDs with ID
    if (invoicePosition > -1) {
      let nextInvoiceIndex
      // Are we going backward or forward?

      // BACKWARD: invoice position - 1; if already at 0, BAIL
      if (direction === 'backward') {
        nextInvoiceIndex = invoicePosition - 1
        // If nextInvoiceIndex = -1, BAIL
        if (nextInvoiceIndex === -1) {
          CLog('Next index was -1')
          return 'at-beginning'
        }
      }
      // FORWARD: invoice position + 1; if already at IDs.length, BAIL
      else if (direction === 'forward') {
        nextInvoiceIndex = invoicePosition + 1
        // If nextInvoiceIndex is greater than the length of the ids from the current search
        if (nextInvoiceIndex > invoiceIDs.length - 1) {
          CLog('Next index was passed length of invoice IDs')
          return 'at-end'
        }
      }
      // This is caught when a user clicks on a load # of a pending item
      else {
        nextInvoiceIndex = invoicePosition
      }

      // Update LS with the incoming invoice position
      localStorage.setItem('search', JSON.stringify({
        ...JSON.parse(localStorage.getItem('search')),
        lastPosition: nextInvoiceIndex,
      }))

      // RETURN THE ID OF THE NEXT INVOICE USING THE NEXT INVOICE INDEX
      const nextInvoiceID = invoiceIDs[nextInvoiceIndex]
      // For debugging
      CLog(
        `%c INVOICE-DETAILS NAV INFO::
          INCOMING INVOICES:
            next invoice: ${nextInvoiceID}
            next invoice position: ${nextInvoiceIndex}
            previous invoice: ${id}
            previous invoice position: ${invoicePosition}
            search params: ${lsSearch.urlString}
        `,
        'color: purple; font-size: 16px;'
      )

      return nextInvoiceID
    }
    // If there isn't a match in IDs with ID
    // eslint-disable-next-line no-else-return
    else {
      // For debugging:
      CLog('Invoice ID was not found in search IDs')
      const lastPosition = JSON.parse(localStorage.getItem('search')).lastPosition
      CLog(`Last Position was: ${lastPosition}`)
      // We want to return the last known position if there isn't a match
      // We have to specifically look for undefined since array[empty index] === undefined
      // i.e. were at the end of the array of IDs and the last position was 1 index too far
      if (invoiceIDs[lastPosition] === undefined || !invoiceIDs[lastPosition]) {
        return invoiceIDs[0]
      }
      return invoiceIDs[lastPosition]
      // return lastPosition ? invoiceIDs[lastPosition] : invoiceIDs[0]
    }
  }
  // If IDs do not have length
  // eslint-disable-next-line no-else-return
  else {
    // For debugging:
    CLog('IDs is empty; Invoice ID was not found in search IDs')
    return 'ids-empty'
  }
}

export const goToNextInvoice = async (id, router, alertWarning, direction = null) => {
  const currentRouteID = window.location.pathname.replace('/invoice-details/', '')
  const debugObject = {
    currentInvoice: id,
    direction,
    router,
  }
  const lsSearch = JSON.parse(localStorage.getItem('search'))
  const nextInvoiceID = await getNextInvoicePosition(id, direction)
  debugObject.currentRouteID = currentRouteID
  debugObject.lsSearch = lsSearch
  debugObject.nextInvoice = nextInvoiceID

  // Alert combinations (next invoice position not found):
  let showNext = false
  let showPrevious = false
  const invoiceDetailsAlert = () => {
    debugObject.showNext = showNext
    debugObject.showPrevious = showPrevious
    alertWarning(true, showPrevious, showNext)
    CLog(debugObject)
  }

  switch (nextInvoiceID) {
    // This is the case when a user comes to invoice-details from universal search
    // I'm essentially disabling invoice navigation in this scenario
    case 'search-is-null':
      // For debugging
      console.log(
        `%c INVOICE-DETAILS NAV INFO::
          "search" in LS is null, routing to the ONE invoice
        `,
        'color: purple; font-size: 16px;'
      )
      // For debugging
      debugObject.nextInvoiceID = id
      debugObject.previousInvoice = null

      // Before routing, check if `id` is the same as the current page's id
      // (prevent duplicate navigation)
      // This also sends a user back to pending if they change the status of
      // an invoice after searching from universal search
      if (id === currentRouteID) {
        router.push({ name: 'pending' })
        return
      }

      router
        .push({
          name: 'invoice-details',
          params: {
            disabledNavigation: true,
            id: id.toString()
          },
        })
        .catch(error => {
          CError(error)
          captureSentryEvent(
            'Helpers: "GoToNextInvoice" from universal search',
            {
              config: error.config,
              details: error,
              functionVariables: debugObject,
              response: error.response,
            }
          )
        })

      break

    // At beginning of IDs: hide alert, back to pending, next invoice
    case 'at-beginning':
      // For debugging
      console.log(
        `%c INVOICE-DETAILS NAV INFO::
          Navigation is already at the beginning
        `,
        'color: purple; font-size: 16px;'
      )
      showNext = true
      invoiceDetailsAlert()
      break

    // At end of IDs: hide alert, back to pending, previous invoice,
    case 'at-end':
      // For debugging
      console.log(
        `%c INVOICE-DETAILS NAV INFO::
          Navigation is already at the end
        `,
        'color: purple; font-size: 16px;'
      )
      showPrevious = true
      invoiceDetailsAlert()
      break

    // Cannot find ID in IDs, but IDs have length: hide alert, next invoice (loads 0)
    case 'id-not-in-ids':
      // For debugging
      console.log(
        `%c INVOICE-DETAILS NAV INFO::
          ID was not found in IDs (LS)
        `,
        'color: purple; font-size: 16px;'
      )
      showNext = true
      invoiceDetailsAlert()
      break

    // Cannot find ID in IDs and IDs do NOT have length: hide alert, back to pending
    case 'ids-empty':
      // For debugging
      console.log(
        `%c INVOICE-DETAILS NAV INFO::
          IDs is empty
        `,
        'color: purple; font-size: 16px;'
      )
      invoiceDetailsAlert()
      break
    // If a position for the next invoice is found
    default:
      // For debugging
      debugObject.nextInvoiceID = nextInvoiceID
      debugObject.previousInvoice = id

      router
        .push({
          name: 'invoice-details',
          params: { id: nextInvoiceID.toString() },
        })
        .catch(error => {
          CError('GoToNextInvoice error', error)
          console.log(
            `
              nextInvoice:${nextInvoiceID},
              previousInvoice: ${id},
              routerID:${currentRouteID}
            `
          )
          captureSentryEvent(
            'Helpers: GoToNextInvoice"',
            {
              config: (error && error.config) ? error.config : '',
              details: error,
              functionVariables: debugObject,
              response: (error && error.response) ? error.response : '',
            }
          )
        })
  }
}

export const viewDocument = async (id) => {
  try {
    const doc = (await ClientDocument.get(id)).data.url
    window.open(doc)
  }
  catch (error) {
    captureSentryEvent(
      'Helpers: viewDocument',
      {
        config: error.config,
        details: error,
        id,
        response: error.response,
      }
    )
    CError(error)
  }
}
