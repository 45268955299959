import Entity from './entity'
import bobtailAPI from '../axiosConfig'

const ClientBankAccount = new Entity('clientbankaccounts')

ClientBankAccount.getPlaidPublicToken = async (params) => {
  return (await bobtailAPI()).post(`${ClientBankAccount.baseUrl}/publicplaidtoken/${params.id}`, {
    params
  })
}

ClientBankAccount.getRoutingNumberBankRelationshipVerification = async (number) => {
  return (await bobtailAPI()).get(`${ClientBankAccount.baseUrl}/verifyroutingnumber/${number}`)
}

ClientBankAccount.postAutomaticDepositVerification = async (params) => {
  return (await bobtailAPI()).post(`${ClientBankAccount.baseUrl}/plaid_trigger_verification_status/${params.id}`, {
    // verification_status: ['verification_expired', 'automatically_verified']
    ...params,
  })
}

// As of 20210625, Plaid's sandbox test credentials to add a bank
// in the state of ITEM_LOGIN_REQUIRED does not work, so we're manually
// manipulating the bank's state
ClientBankAccount.postItemLoginRequiredState = async (params) => {
  return (await bobtailAPI()).post(`${ClientBankAccount.baseUrl}/plaid_set_bank_to_ITEM_LOGIN_REQUIRED/${params.id}`, {
    ...params,
  })
}

ClientBankAccount.postWireRoutingNumber = async (params) => {
  return (await bobtailAPI()).post(`${ClientBankAccount.baseUrl}/wirebankaccounttomoderntreasury/${params.id}`, {
    ...params,
  })
}

ClientBankAccount.updateWireRoutingNumber = async (params) => {
  return (await bobtailAPI()).put(`${ClientBankAccount.baseUrl}/wirebankaccounttomoderntreasury/${params.id}`, {
    ...params,
  })
}

ClientBankAccount.deleteWireRoutingNumber = async (params) => {
  return (await bobtailAPI()).delete(`${ClientBankAccount.baseUrl}/wirebankaccounttomoderntreasury/${params.id}`, {
    ...params,
  })
}

export default ClientBankAccount