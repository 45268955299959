<template>
  <div
    v-click-outside="clickedOutside"
    class="BaseUserSearch column"
    data-cy="debtor-search"
  >
    <label
      v-if="label"
      class="BaseUserSearch__label fc-light fs-12 uppercase no-select"
      :for="labelFor"
    >
      <slot name="label" />
    </label>
    <input
      v-model.trim="input"
      @input="input = $event.target.value.trim()"
      @keydown.40.prevent="moveToResults"
      autocomplete="off"
      :class="[
        'BaseUserSearch__input bg-white fs-14',
        { 'BaseUserSearch__input--error': !valid && !debtor },
        { 'BaseUserSearch__input--w-dropdown': dropdown }
      ]"
      data-cy="debtor-search-input"
      :id="labelFor"
      :placeholder="placeholder"
      ref="BaseUserSearch__input"
    >
    <transition name="Transition__fade">
      <label
        v-if="!valid && !debtor"
        class="fc-red fs-12"
      >
        {{ instructions }}
      </label>
    </transition>
    <div class="BaseUserSearch__list-wrapper">
      <ul
        v-if="results.length && dropdown"
        @keydown.40.prevent="moveDownResults"
        @keydown.38.prevent="moveUpResults"
        class="BaseUserSearch__list bg-white"
        ref="BaseUserSearch__list"
      >
        <li
          v-for="(debtorResult, index) in results"
          :key="index"
          @click="select(debtorResult, $event)"
          @keypress.space.enter="select(debtorResult, $event)"
          class="BaseUserSearch__list-item column"
          :data-cy="`debtor-search-result-${index}`"
          tabindex="0"
        >
          <div class="row row--align-center">
            <template v-if="debtorResult.name === 'debtor not found'">
              <div class="row row--justify-center">
                <span class="'fs-14'">Debtor Not Found</span>
              </div>
            </template>
            <template v-else>
              <span :class="debtorRatingStyles(debtorResult)">{{ debtorResult.display_rating }}</span>
              <div class="column">
                <span class="fs-14 uppercase">
                  {{
                    debtorResult.name
                      ? filterAlgoliaDebtorName(debtorResult.name)
                      : ''
                  }}
                </span>
                <span class="fc-light fs-12">{{ debtorResult.city }}, {{ debtorResult.state }}</span>
                <span class="fc-light fs-12">
                  {{ debtorResult.broker ? `MC ${debtorResult.broker.mc}` : '' }}
                </span>
              </div>
            </template>
          </div>
        </li>
        <li
          v-if="page < maxSearchPages"
          @click="loadMoreResults"
          @keypress.space.enter="loadMoreResults"
          class="
            BaseUserSearch__list-item BaseUserSearch__load-more-results
            column fc-light fs-12 fw-med uppercase
          "
          tabindex="0"
        >
          Load More Results
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// Packages
import DOMPurify from 'dompurify'
// Helpers
import { Debtor } from '../utils/api'
import { sanitizeAlgoliaNames } from '../utils/helpers'

export default {
  name: 'BaseUserSearch',

  props: {
    debtorIDFromQuery: {
      type: [Number, String],
      required: false,
      default: null,
    },
    hideDebtorNotFound: {
      type: Boolean,
      required: false,
      default: false,
    },
    instructions: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelFor: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    resetAfterSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    statuses: {
      type: Array,
      required: false,
      default: () => {
        return ['active']
      }
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  async created () {
    if (!this.debtorIDFromQuery) return
    this.getDebtor(this.debtorIDFromQuery)
  },

  data () {
    return {
      debtor: null,
      dropdown: false,
      input: '',
      maxSearchPages: null,
      page: 0,
      results: [],
    }
  },

  watch: {
    debtorIDFromQuery (newValue, oldValue) {
      // If this.debtorIDFromQuery doesn't exist on created,
      // oldValue will be null when freshly selecting a result
      if (oldValue === null) return
      // When going from a value to erasing the input's value, reset component data
      if (newValue === null) {
        this.resetData()
        return
      }
      // When the incoming this.debtorIDFromQuery is different than the current
      if (oldValue.id !== newValue.id) {
        this.getDebtor(newValue)
      }
    },

    input (newValue, oldValue) {
      if (this.input === '') {
        this.debtor = null
        this.dropdown = false
        this.maxSearchPages = 0
        this.page = 0
        this.results = []
        // Let parent components know there is NO selected
        // Do NOT report null if reseting after selected (base-select-search), because
        // this will trigger a double route to a debtor page with a null value for the ID
        if (!this.resetAfterSelected) this.$emit('selected', null)
        return
      }
      // Hide the dropdown if this.debtor.name (gotten from created().getDebtor)) === this.input
      if (this.debtor && this.input === this.filterAlgoliaDebtorName(this.debtor.name)) {
        this.dropdown = false
        return
      }
      // Reset pagination if search value is different
      if (newValue !== oldValue) this.page = 0
      this.search()
    },
  },

  methods: {
    clickedOutside () {
      this.dropdown = false
      this.searching = false
      this.results = []
      this.page = 0
      if (this.debtor) this.input = this.debtor.name
      else this.input = ''
      this.$emit('selected', this.debtor)
    },

    debtorRatingStyles (debtor) {
      return {
        'BaseUserSearch__list-item-rating fs-14 fw-med': true,
        'BaseUserSearch__list-item-rating--caution': ['c', 'd'].includes(debtor.display_rating.toLowerCase()),
        'BaseUserSearch__list-item-rating--danger': debtor.display_rating.toLowerCase() === 'f',
        'BaseUserSearch__list-item-rating--green': ['a', 'b'].includes(debtor.display_rating.toLowerCase()),
      }
    },

    filterAlgoliaDebtorName (name) {
      return sanitizeAlgoliaNames(name)
    },

    async getDebtor (id) {
      try {
        this.debtor = (await Debtor.get(id)).data
        this.input = this.filterAlgoliaDebtorName(this.debtor.name)
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Search "Get Debtor" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
      }
    },

    loadMoreResults () {
      this.page += 1
      this.search()
    },

    moveDownResults (e) {
      const nextSibling = e.target.nextSibling
      // #comment represents the hidden load more button
      if (nextSibling === null || nextSibling.nodeName === '#comment') return
      nextSibling.focus()
    },

    moveToResults () {
      if (!this.results.length) return
      this.$refs.BaseUserSearch__list.childNodes[0].focus()
    },

    moveUpResults (e) {
      const previousSibling = e.target.previousSibling
      if (previousSibling === null) {
        this.$refs.BaseUserSearch__input.focus()
        return
      }
      previousSibling.focus()
    },

    resetData () {
      Object.assign(this.$data, this.$options.data())
    },

    async search () {
      this.dropdown = true
      try {
        const filters = `status:${this.statuses.join(' OR status:')}`
        const search = `${DOMPurify.sanitize(this.input)}&filters=${filters}`
        const query = `${DOMPurify.sanitize(this.input)}`

        const result = (await Debtor.search({
          filters,
          includeNotFoundDebtor: this.hideDebtorNotFound ? null : true,
          page: this.page,
          query
        }, 5)).data
        this.maxSearchPages = result.nbPages - 1

        // [BANDAID](unsure of cause) addresses error where a null value is returned in results
        this.results = result.hits.filter(hit => {
          if (!hit) {
            // log this in sentry for better debugging
            this.captureSentryEvent('NULL in Debtor Search', {
              data: this.$data,
              params: search,
              props: this.$props,
              results: result.hit
            }, 'debug')
          }
          return hit
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue finding matching debtors' })
      }
    },

    select (result, event) {
      event.preventDefault()
      this.dropdown = false
      this.debtor = result
      this.input = this.filterAlgoliaDebtorName(result.name)
      this.maxSearchPages = 0
      this.page = 0
      this.results = []
      this.$emit('selected', this.debtor)
      if (this.resetAfterSelected) this.resetData()
      this.$refs.BaseUserSearch__input.focus()
    },
  },
}
</script>

<style lang="sass">
@import '../styles/user-search.sass'

.BaseUserSearch

  &__list-item
    padding: rem(16px)
</style>
