<template>
  <div
    v-if="client.id"
    class="UserProfile"
  >
    <!-- Controlled Login (MASTERS only) -->
    <div class="UserProfile__control-btn-container row row--justify-end">
      <base-button
        v-if="user.employee ? user.employee.is_master : false"
        @click="assumeClientControl"
        class="fc-white bg-blue fs-14"
      >
        Control Client
      </base-button>
    </div>

    <user-profile-navigation
      :id="id"
      :page-p-o-v="'client'"
      :routes="routes"
      :user="client"
      :user-is-client="userIsClient"
    />

    <!-- Wait to show the client pages until getClient has completed (successfully/failed) -->
    <transition name="Transition__opacity-fade">
      <router-view
        v-if="!getClientInProgress"
        class="UserProfile__router-view"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Balance,
  Client,
  Users
} from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import UserProfileNavigation from '../../../components/user-profile-navigation.vue'

export default {
  name: 'Client',

  components: {
    BaseButton,
    UserProfileNavigation,
  },

  props: {
    id: {
      type: String,
      required: true
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  beforeRouteEnter (to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.client) {
      // Prevent clients going to other clients' account pages or pages other than their own payments
      if (to.params.id === user.client.id && to.name === 'client-activity') {
        next()
      } else {
        next({ name: 'client-activity', params: { id: user.client.id.toString() } })
      }
    }
    else {
      next()
    }
  },

  async created () {
    // Add dashboard route for employees (not visible by clients as they can go to dashboard in nav)
    this.setEmployeeDashboardRoute()

    // Used to determine if we show the control client button
    this.user = JSON.parse(localStorage.getItem('user'))

    await this.getClient()
  },

  beforeDestroy () {
    this.$store.commit('CLEAR_CLIENT')
  },

  data () {
    return {
      getClientInProgress: false,
      routes: process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal'
        ? [
          {
            text: 'activity',
            to: { name: 'client-activity' },
          },
          {
            text: 'profile',
            to: { name: 'client-profile' },
          },
          {
            text: 'contacts',
            to: { name: 'client-contacts' },
          },
          {
            text: 'docs',
            to: { name: 'client-docs' },
          },
          {
            text: 'assignments',
            to: { name: 'client-assignments' },
          },
          {
            text: 'status',
            to: { name: 'client-status' },
          },
          {
            text: 'processing notes',
            to: { name: 'client-processing-notes' },
          },
        ]
        : [
          {
            text: 'activity',
            to: { name: 'client-activity' },
          },
          {
            text: 'profile',
            to: { name: 'client-profile' },
          },
          {
            text: 'contacts',
            to: { name: 'client-contacts' },
          },
          {
            text: 'docs',
            to: { name: 'client-docs' },
          },
          {
            text: 'assignments',
            to: { name: 'client-assignments' },
          },
          {
            text: 'data',
            to: { name: 'client-data' },
          },
          {
            text: 'status',
            to: { name: 'client-status' },
          },
          {
            text: 'processing notes',
            to: { name: 'client-processing-notes' },
          },
        ],
      user: null,
    }
  },

  watch: {
    $route () {
      // Required to prevent a client user from viewing other pages by visting it via the URL, then
      // hitting the back arrow in their browser after their redirected to their payments page
      const user = JSON.parse(localStorage.getItem('user'))
      if (user.client) {
        if (this.$route.params.id !== user.client.id || this.$route.name !== 'client-activity') {
          this.$router.push({
            name: 'client-activity',
            params: {
              id: user.client.id.toString(),
            },
          }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        }
      }
    },

    // Use Case: when searching and selecting a client from the base-select-search while you're
    // on user-activity, we must track for ID prop changes to get the new client info, because
    // simply passing a different ID to the router push function in base-select-search
    // won't trigger a page load since activity is the current route
    async id () {
      this.setEmployeeDashboardRoute()
      await this.getClient()
    },
  },

  computed: {
    client () {
      return this.$store.getters.client
    },
  },

  methods: {
    async assumeClientControl () {
      // 1. Get controlled client's data
      const controlledClient = (await Users.getClientData({
        client_id: this.id
      })).data.user
      // 2. Set current user as master in localStorage
      localStorage.setItem('master', JSON.stringify(this.user))
      // 3. Set controlledClient as controlledUser in localStorage
      localStorage.setItem('controlledClient', JSON.stringify(controlledClient))
      // 4. Set controlledClient as user in localStorage
      localStorage.setItem('user', JSON.stringify(controlledClient))
      // 5. Route to '/' (beginning the controlled experience)
      this.requestSuccess({
        message: `You have now logged in as ${controlledClient.client.shortened_name}. Redirecting...`
      })
      window.open(`${process.env.VUE_APP_APP_URL}`, '_self')
    },

    async getClient () {
      this.$Progress.start()
      this.getClientInProgress = true

      try {
        this.$store.commit('STORE_CLIENT', (await Client.get(this.id)).data)
        await this.getClientBalance()
        this.$Progress.finish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client "Get Client"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CLog(error.response)
        if (error.response && error.response.status === 404) {
          this.requestFailure({
            message: `We did not find a client by that ID (${this.$route.params.id})`
          })
          this.$router.push({ name: 'user-management' })
          return
        }
        this.requestFailure({ message: 'There was an issue fetching the client' })
      }

      this.getClientInProgress = false
    },

    async getClientBalance () {
      try {
        this.$store.commit('UPDATE_STORED_CLIENT', {
          balance: (await Balance.getClientTotalBalance(this.id)).data.total
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'Client "Get Balance"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
      }
    },

    setEmployeeDashboardRoute () {
      // Add dashboard route for employees (not visible by clients as they can go to dashboard in nav)
      const dashboardIndex = this.routes.findIndex(route => route.text === 'dashboard')
      if (!this.userIsClient) {
        // If dashboard route exists, update it; if dashboard route does not exist (-1), add it
        if (dashboardIndex >= 0) {
          this.$set(this.routes[dashboardIndex].to, 'params', { id: this.id })
        }
        else {
          this.routes.push({
            text: 'dashboard',
            to: {
              name: 'dashboard-client',
              params: {
                id: this.id,
              },
            }
          })
        }
      }
    },
  },
}
</script>

<style lang="sass">
@import '../../../styles/user-profile.sass'
</style>
