var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentRoute
    ? _c(
        "div",
        {
          staticClass: "NavMobile width-100",
          attrs: { "data-cy": "nav-mobile" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "NavMobile__header bg-blue row row--align-center row--justify-center"
            },
            [
              _c(
                "button",
                {
                  staticClass: "NavMobile__home-btn",
                  attrs: {
                    "aria-label": "Mobile menu button",
                    id: "Testing__NavMobileMenuBtn"
                  },
                  on: { click: _vm.goToMobileHome }
                },
                [_c("i", { staticClass: "fa fa-house fa--none fc-white" })]
              ),
              _c(
                "h1",
                {
                  staticClass:
                    "NavMobile__header-lbl fc-white fs-22 fw-med capitalize",
                  attrs: { id: "Testing__NavMobileHeader" }
                },
                [_vm._v("\n      " + _vm._s(_vm.currentRoute) + "\n    ")]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }