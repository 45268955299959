import bobtailAPI from '../axiosConfig'

export default class QuickbooksRaw {
  static baseUrl = '/api/quickbooksraw'

  static async quickbooksRefresh (params) {
    return (await bobtailAPI()).get(`${QuickbooksRaw.baseUrl}/refreshQuickbooksTokens`, {
      params
    })
  }
}