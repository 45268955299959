import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const DebtorPayment = new Entity('debtorpayments')

DebtorPayment.createJournalEntry = async (params) => {
  return (await bobtailAPI()).post(`${DebtorPayment.baseUrl}/journal-entries`, {
    ...params
  })
}

DebtorPayment.getJournalEntries = async (params) => {
  return (await bobtailAPI()).get(`${DebtorPayment.baseUrl}/journal-entries`, {
    params
  })
}

export default DebtorPayment
