import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Balance = new Entity('balances')

Balance.createJournalEntry = async (params) => {
  return (await bobtailAPI()).post(`${Balance.baseUrl}/journal-entries`, {
    ...params
  })
}

Balance.getClientBalances = async (params) => {
  return (await bobtailAPI()).get(Balance.baseUrl, {
    params
  })
}

Balance.getClientTotalBalance = async (id) => {
  return (await bobtailAPI()).get(`${Balance.baseUrl}/total/${id}`)
}

Balance.getJournalEntries = async (params) => {
  return (await bobtailAPI()).get(`${Balance.baseUrl}/journal-entries`, {
    params
  })
}

Balance.getDiscrepancies = async (params) => {
  return (await bobtailAPI()).get(`${Balance.baseUrl}/discrepancies`, {
    params
  })
}

Balance.historyDiscrepancies = async (params) => {
  return (await bobtailAPI()).get(`${Balance.baseUrl}/history-discrepancies`, {
    params
  })
}

Balance.fixDiscrepancies = async (params) => {
  return (await bobtailAPI()).get(`${Balance.baseUrl}/fix-discrepancies`, {
    params
  })
}

export default Balance