import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const BatchPayment = new Entity('batchpayments')

BatchPayment.createJournalEntry = async (id) => {
  return (await bobtailAPI()).post(`${BatchPayment.baseUrl}/journal-entries/${id}`)
}

BatchPayment.getJournalEntries = async (id) => {
  return (await bobtailAPI()).get(`${BatchPayment.baseUrl}/journal-entries/${id}`)
}

export default BatchPayment