<template>
  <div
    v-if="mediaQueryList"
    :class="['App column', {
      'mb-40': nonProdEnvironment,
    }]"
    id="app"
  >
    <base-alert
      v-if="nonProdEnvironment"
      :class="['App__environment-label', {
        'bg-green': environment === 'staging',
        'bg-red': !['staging', 'qa'].includes(environment),
        'bg-yellow': environment === 'qa'
      }]"
      :disable-close="true"
    >
      <template v-slot:message>
        <label class="fc-white uppercase">
          {{ `${environment} - ${releaseVersion}` }}
        </label>
      </template>
    </base-alert>
    <!-- fixed positioned at the top (Desktop ONLY) -->
    <transition name="Transition__opacity-fade">
      <base-alert
        v-if="
          baseAlertMessage
            && baseAlertActions
            && !alertManuallyClosed
            && !mediaQueryList.matches
        "
        @close-alert="alertManuallyClosed = true"
      >
        <template v-slot:message>
          <label class="fc-white">{{ baseAlertMessage }}&nbsp;</label>
        </template>

        <template v-slot:actions>
          <router-link
            :to="baseAlertActions.to"
            class="fc-white underline no-wrap"
          >
            {{ baseAlertActions.message }}
          </router-link>
        </template>
      </base-alert>
    </transition>
    <!-- fixed positioned at the top -->
    <vue-progress-bar />
    <!-- fixed positioned at the top -->
    <toastr-alert />
    <!-- Exit button for controlling client -->
    <base-button
      v-if="controlledClient"
      @click="exitControl"
      class="bg-red fc-white"
    >
      Exit Control
    </base-button>
    <!-- BANKING MODAL (CLIENT AND DESKTOP ONLY) -->
    <transition name="Transition__opacity-fade">
      <base-modal
        v-if="showBankRequiredModal"
        data-cy="bank-required-modal"
      >
        <template v-slot:label>
          Welcome to Bobtail! Please set up your bank information.
        </template>
        <template v-slot:actions>
          <base-button
            @click="goToClientBanking"
            class="bg-blue fc-white"
            data-cy="bank-required-modal-go-to-bank-btn"
          >
            Go to banking
          </base-button>
        </template>
      </base-modal>
    </transition>

    <div class="App__wrapper row">
      <navigation
        v-if="authenticatedUser"
        :user="user"
      />
      <div class="column">
        <!-- These two components should only be visible on certain pages, if a user exists,
        and if it's desktop -->
        <div
          v-if="user && !mobile.isMobile && visible"
          class="row row--justify-between row--align-end"
        >
          <base-select-search :user="user" />
          <!-- "Welcome, [client]" for clients on desktop -->
          <div
            v-if="user.client"
            class="App__client-welcome-text-container row row--align-center row--width-auto"
          >
            <label class="fc-light mr-4">Welcome,</label>
            <label
              class="fw-med"
              data-cy="client-shortened-name"
            >
              {{ user.client.shortened_name }}
            </label>
          </div>
        </div>
        <router-view :class="['App__router-view', { 'mobile-browsing': mobile.isMobile }]" />
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
// Helpers
import { getCurrentJWTToken } from './utils/cognito'
import {
  ClientBankAccount,
  Tenant,
} from './utils/api'
// Components
import BaseAlert from './components/base-alert.vue'
import BaseButton from './components/base-button.vue'
import BaseModal from './components/base-modal.vue'
import BaseSelectSearch from './components/base-select-search.vue'
import Navigation from './components/navigation.vue'


export default {
  name: 'App',

  components: {
    BaseAlert,
    BaseButton,
    BaseModal,
    BaseSelectSearch,
    Navigation,
  },

  async created () {
    // initialize Sentry
    this.initializeSentry()

    // Look for controlled client
    this.controlledClient = localStorage.getItem('controlledClient')

    // Set matchMedia to update mobile state app-wide
    this.initMediaQueryList()

    // If token exists and user doesn't need to login...
    if (await getCurrentJWTToken()) {
      this.CLog('App Created: token exists and user doesn\'t need to login')
      this.authenticatedUser = true
      this.user = JSON.parse(localStorage.getItem('user'))
      await this.checkForActiveBank()
      this.setSelectVisibility()
    }

    // If user logs in...
    // login.vue fires 'login' - ENSURES navigation displays on login
    this.$root.$on('login', async () => {
      this.CLog('App Created: user logged in')
      this.authenticatedUser = true
      this.user = JSON.parse(localStorage.getItem('user'))
      await this.checkForActiveBank()
    })
  },

  data () {
    return {
      activeBank: null,
      alertManuallyClosed: false,
      authenticatedUser: false,
      clientBanks: [],
      controlledClient: null,
      environment: process.env.VUE_APP_ENV,
      mediaQueryList: null,
      nonProdEnvironment: !['prod', 'demo'].includes(process.env.VUE_APP_ENV),
      releaseVersion: process.env.VUE_APP_GIT_RELEASE_VERSION,
      user: null,
      visible: false,
    }
  },

  computed: {
    baseAlertActions () {
      // If no user (expired tokens/etc)
      if (!this.user) return
      // Don't compute for employees
      if (this.user.employee) return
      // this.activeBank, if it exists, isn't available when computed is computed for first time
      if (!this.activeBank) return
      // We only care about manual verification cases (base-bank.vue validation computed)
      if (
        !['pending_manual_verification', 'verification_expired']
          .includes(this.activeBank.plaid_verification_status)
      ) {
        return {
          message: 'Click here',
          to: { name: 'dashboard-client-banking', params: { id: this.user.client.id } },
        }
      }
      return null
    },

    baseAlertMessage () {
      // this.activeBank, if it exists, isn't available when computed is computed for first time
      if (!this.activeBank) return
      // Don't compute for employees
      if (!this.user || !this.user.employee) return
      // We only care about manual verification cases (base-bank.vue validation computed)
      if (
        ['pending_manual_verification', 'verification_expired']
          .includes(this.activeBank.plaid_verification_status)
      ) {
        return `Your ${this.activeBank.bank_name} bank account needs to be verified.`
      }
      return null
    },

    clientWithoutBankModal () {
      return this.$store.getters.clientWithoutBankModal
    },

    mobile () {
      return this.$store.getters.mobile
    },

    showBankRequiredModal () {
      if (
        // Client doesn't have a bank
        this.clientWithoutBankModal
        // We're not on mobile
        && !this.mediaQueryList.matches
        // The route isn't one of these
        && !['dashboard-client-banking', 'login', 'maintenance'].includes(this.$route.name)
      ) return true
      return false
    },
  },

  watch: {
    // User data (user, activeBank, clientBanks, controlledClient, etc) need reset when logging out
    $route (newRoute) {
      if (newRoute.name === 'login') {
        this.resetData()
      }
      // When watching the route, newRoute is far more accurate for the "current" route, or
      // at least the route we want when checking for navigation's visibility
      // By checking the route we're GOING to, it's a faster, more predictable method
      // of controlling when the navigation is displayed
      this.setSelectVisibility(newRoute)
    }
  },

  methods: {
    addMediaQueryListener () {
      this.mediaQueryList.addListener(this.updateMatch)
    },

    async checkForActiveBank () {
      if (this.user && this.user.client) {
        // TODO: remove for performance later
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2166
        // When we trigger maintenance mode, clients who are logged in can get this potential issue where
        // they are repeatedly redirected back and forth between /maintenance and /X that tries to load
        // their active bank account, but some request causes it to fail and the "welcome to bobtail, add
        // a bank" modal keeps flashing
        // This prevents this request from running, but it's just a bandaid
        const maintenanceOn = (await Tenant.fetchMaintenanceMode()).data.maintenance_mode
        if (maintenanceOn) return

        // Get user's bank account info
        await this.getClientBanks(this.user.client.id)

        if (!this.clientBanks.length || !this.clientBanks.some(bank => bank.status === 'active')) {
          this.$store.commit('STORE_CLIENT_WITHOUT_BANK_MODAL', true)
        } else {
          // This, and the next else statement, are to prevent changing users and the bank modal
          // staying active whether relevant or not
          this.$store.commit('STORE_CLIENT_WITHOUT_BANK_MODAL', false)
          this.activeBank = this.clientBanks.filter(bank => bank.status === 'active')[0]
        }
      } else {
        // Disable for all employees
        this.$store.commit('STORE_CLIENT_WITHOUT_BANK_MODAL', false)
      }
    },

    exitControl () {
      // 1. Reset user from master in localStorage
      const master = localStorage.getItem('master')
      localStorage.setItem('user', master)
      // 2. Remove master and controlledClient in localStorage
      const controlledClient = JSON.parse(localStorage.getItem('controlledClient'))
      localStorage.removeItem('controlledClient')
      localStorage.removeItem('master')
      // 3. Route to the client having been controlled (ending the )
      this.requestSuccess({
        message: `You have now logged out as ${controlledClient.client.shortened_name}. Redirecting...`
      })
      window.open(`${process.env.VUE_APP_APP_URL}/client/${controlledClient.client.id}/activity`, '_self')
    },

    async getClientBanks (id) {
      try {
        this.clientBanks = (await ClientBankAccount.queryList({
          client_id: id,
        })).data.rows
      }
      catch (error) {
        this.captureSentryEvent(
          'App CREATED "Get Banks"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            id,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s bank accounts' })
      }
    },

    goToClientBanking () {
      this.$router.push({
        name: 'dashboard-client-banking',
        params: {
          // Only auto trigger adding a new bank if this.clientBanks still equals null or []
          autoTriggerNewBank: !this.clientBanks.length,
          id: this.user.client.id,
        }
      })
    },

    initMediaQueryList () {
      // This is being copied in /marketing-website/layouts/default.vue
      // If "Get mobile state" functionality is edited, please make said changes
      // in /marketing-website
      // Get mobile state
      this.mediaQueryList = window.matchMedia('(max-width: 1024px)')
      // Keep mobile state updated: monitor screen res or zoom level changes
      this.addMediaQueryListener()
      this.updateMatch()
    },

    resetData () {
      Object.assign(this.$data, this.$options.data())
      this.initMediaQueryList()
    },

    setSelectVisibility (newRoute = null) {
      const route = newRoute ? newRoute.name : this.$route.name
      // Navigation doesn't display on these pages
      this.visible = ![
        'admin-login',
        'invoice-details',
        'login',
        'maintenance',
        'page-not-found',
        'password-reset',
        'permission-denied',
        'privacy',
        'release-notes',
        'set-password',
        'styleguide',
        'terms',
        null
      ].includes(route)
    },

    updateMatch () {
      this.$store.commit('STORE_IS_MOBILE', this.mediaQueryList.matches)
    },
  },
}
</script>

<style lang="sass">
@import './styles/base/normalize.sass'
@import './styles/base/icons.sass'
@import './styles/base/typography.sass'
@import './styles/base/spacing.sass'
@import './styles/base/color-classes.sass'
@import './styles/base/transitions.sass'
@import './styles/v-select.sass'

// Depending on the resolution: (account for side menu)
#app
  display: flex
  width: 100%

.column
  +flex($direction: column)
  width: 100%

  &--align-start
    align-items: flex-start

  &--align-center
    align-items: center

  &--align-end
    align-items: flex-end

  &--justify-center
    justify-content: center

  &--justify-between
    justify-content: space-between

  &--justify-end
    justify-content: flex-end

  &--width-auto
    width: auto

.Divider
  background-color: transparent
  border: none
  border-bottom: rem(1px) solid $borders
  width: 100%

.mobile-browsing
  // Height of nav header on mobile
  margin-top: rem(60px) !important
  // TODO: Remove if Chatlio gets removed
  padding-bottom: 6rem !important

.row
  +flex()
  width: 100%

  &--wrap
    flex-wrap: wrap

  &--align-center
    align-items: center

  &--align-end
    align-items: flex-end

  &--justify-center
    justify-content: center

  &--justify-between
    justify-content: space-between

  &--justify-end
    justify-content: flex-end

  &--width-auto
    width: auto

// Helper classes
.clickable
  cursor: pointer

.width-100
  width: 100%

.App
  min-height: 100vh

  &__client-welcome-text-container
    height: $input-height

  &__environment-label
    bottom: 0
    left: 0
    position: fixed
    // This label is only on development environments, so this won't affect anything
    z-index: 999

    // We want to center the environment alert text since our application is centered
    // and we're hiding the close button
    .BaseAlert__content
      .row
        justify-content: center

  &__wrapper
    margin: 0 auto
    max-width: rem(1440px)
    padding: 0

    .BaseSelectSearch
      // This aligns the top of the component with the top of the nav Bobtail logo
      margin-top: rem(48px)

  @media #{$tablet-landscape-and-up}
    // This is the spacing between the router-view content and base employee search
    // This will be overriden in individual components that don't display the BaseEmployeeSelectSearch
    &__router-view
      margin-top: rem(52px)

  @media #{$tablet-landscape-to-xlarge}

    &__wrapper
      padding: 0 1rem
</style>
