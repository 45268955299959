var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mediaQueryList
    ? _c(
        "div",
        {
          class: [
            "App column",
            {
              "mb-40": _vm.nonProdEnvironment
            }
          ],
          attrs: { id: "app" }
        },
        [
          _vm.nonProdEnvironment
            ? _c("base-alert", {
                class: [
                  "App__environment-label",
                  {
                    "bg-green": _vm.environment === "staging",
                    "bg-red": !["staging", "qa"].includes(_vm.environment),
                    "bg-yellow": _vm.environment === "qa"
                  }
                ],
                attrs: { "disable-close": true },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "message",
                      fn: function() {
                        return [
                          _c("label", { staticClass: "fc-white uppercase" }, [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.environment + " - " + _vm.releaseVersion
                                ) +
                                "\n      "
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  336170142
                )
              })
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "Transition__opacity-fade" } },
            [
              _vm.baseAlertMessage &&
              _vm.baseAlertActions &&
              !_vm.alertManuallyClosed &&
              !_vm.mediaQueryList.matches
                ? _c("base-alert", {
                    on: {
                      "close-alert": function($event) {
                        _vm.alertManuallyClosed = true
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "message",
                          fn: function() {
                            return [
                              _c("label", { staticClass: "fc-white" }, [
                                _vm._v(_vm._s(_vm.baseAlertMessage) + " ")
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "actions",
                          fn: function() {
                            return [
                              _c(
                                "router-link",
                                {
                                  staticClass: "fc-white underline no-wrap",
                                  attrs: { to: _vm.baseAlertActions.to }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.baseAlertActions.message) +
                                      "\n        "
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2207319115
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _c("vue-progress-bar"),
          _c("toastr-alert"),
          _vm.controlledClient
            ? _c(
                "base-button",
                {
                  staticClass: "bg-red fc-white",
                  on: { click: _vm.exitControl }
                },
                [_vm._v("\n    Exit Control\n  ")]
              )
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "Transition__opacity-fade" } },
            [
              _vm.showBankRequiredModal
                ? _c("base-modal", {
                    attrs: { "data-cy": "bank-required-modal" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function() {
                            return [
                              _vm._v(
                                "\n        Welcome to Bobtail! Please set up your bank information.\n      "
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "actions",
                          fn: function() {
                            return [
                              _c(
                                "base-button",
                                {
                                  staticClass: "bg-blue fc-white",
                                  attrs: {
                                    "data-cy":
                                      "bank-required-modal-go-to-bank-btn"
                                  },
                                  on: { click: _vm.goToClientBanking }
                                },
                                [_vm._v("\n          Go to banking\n        ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2337087071
                    )
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "App__wrapper row" },
            [
              _vm.authenticatedUser
                ? _c("navigation", { attrs: { user: _vm.user } })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "column" },
                [
                  _vm.user && !_vm.mobile.isMobile && _vm.visible
                    ? _c(
                        "div",
                        {
                          staticClass: "row row--justify-between row--align-end"
                        },
                        [
                          _c("base-select-search", {
                            attrs: { user: _vm.user }
                          }),
                          _vm.user.client
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "App__client-welcome-text-container row row--align-center row--width-auto"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "fc-light mr-4" },
                                    [_vm._v("Welcome,")]
                                  ),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fw-med",
                                      attrs: {
                                        "data-cy": "client-shortened-name"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.user.client.shortened_name
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("router-view", {
                    class: [
                      "App__router-view",
                      { "mobile-browsing": _vm.mobile.isMobile }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }