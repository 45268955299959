var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseAlert" }, [
    _c("div", { staticClass: "BaseAlert__content row row--align-center" }, [
      _c(
        "div",
        {
          class: [
            "row row--align-center",
            {
              "row--width-auto": !_vm.disableClose
            }
          ]
        },
        [_vm._t("message"), _vm._t("actions")],
        2
      ),
      !_vm.disableClose
        ? _c(
            "div",
            {
              staticClass: "BaseAlert__close-btn-container row row--width-auto"
            },
            [
              _c(
                "button",
                {
                  staticClass: "BaseAlert__close-icon-btn",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-alert")
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-add fa-rotate-45 fa--none" })]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }