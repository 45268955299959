// Package imports
import algoliasearch from 'algoliasearch'
// Helpers
import bobtailAPI from './axiosConfig'

const env = process.env.NODE_ENV
const algoliaAppId = process.env.ALGOLIA_APP_ID
const algoliaSearchKey = process.env.ALGOLIA_SEARCH_KEY

let clientSearch
let debtorSearch

if (env === 'production' || env === 'staging') {
  const algolia = algoliasearch(algoliaAppId, algoliaSearchKey)
  const client = algolia.initIndex('clients')
  const debtor = algolia.initIndex('debtors')

  clientSearch = (string) => {
    Promise((resolve, reject) => {
      client.search(string, {
        hitsPerPage: 10
      }, (err, content) => {
        if (err) return reject(err)
        return resolve(content)
      })
    })
  }

  // Only used in base-debtor-search
  debtorSearch = (string, page = 0) => {
    Promise((resolve, reject) => {
      debtor.search(string, {
        hitsPerPage: 5,
        page,
      }, (err, content) => {
        if (err) return reject(err)
        return resolve(content)
      })
    })
  }
} else {
  const CLIENT_PATH_URL = '/api/clients/search'
  const DEBTOR_PATH_URL = '/api/debtors/search'

  clientSearch = async (string, hitsPerPage = 25, params = { page: 0 }) => {
    return (await bobtailAPI()).get(CLIENT_PATH_URL, {
      params: {
        query: string,
        hitsPerPage,
        ...params
      }
    })
  }

  // Only used in base-debtor-search
  debtorSearch = async (string, page = 1, includeNotFoundDebtor = true) => {
    return (await bobtailAPI()).get(
      `${DEBTOR_PATH_URL}?hitsPerPage=5&query=${string}&page=${page}${
        includeNotFoundDebtor
          ? '&includeNotFoundDebtor=true'
          : ''
      }`
    )
  }
}

export default {
  clientSearch,
  debtorSearch
}
