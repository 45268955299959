<template>
  <div class="BaseInput">
    <label
      v-if="label"
      class="BaseInput__label uppercase"
      :for="labelFor"
    >
      <slot />
    </label>
    <input
      @input="$emit('input', $event.target.value)"
      @keydown="$emit('keydown', $event)"
      autocomplete="off"
      :class="['BaseInput__input fs-14', { 'BaseInput__input--error': !valid }]"
      data-cy="base-input-input"
      :disabled="disabled"
      :id="labelFor"
      :maxlength="maxLength"
      :minlength="minLength"
      :placeholder="placeholder"
      :type="dataType"
      :value="value"
    >
    <i
      v-if="type === 'password'"
      @click="toggleInputType"
      class="fa fa-view fa--none fc-light clickable"
    />
    <transition name="Transition__fade">
      <label
        v-if="!valid"
        class="fc-red fs-12"
        data-cy="base-input-instructions"
      >
        {{ instructions }}
      </label>
    </transition>
  </div>
</template>

<script>
/*
 * This has a copy in /marketing-website/components/base-input.vue
 * If you alter this, please alter it copy
*/
export default {
  name: 'BaseInput',

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    instructions: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelFor: {
      type: String,
      required: false,
      default: null,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    minLength: {
      type: Number,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },

  data () {
    return {
      // Used to toggle password visibility (does not affect other types of inputs)
      dataType: this.type,
    }
  },

  methods: {
    // Password visibility toggling
    toggleInputType () {
      if (this.type !== 'password') return
      // eslint-disable-next-line no-unused-expressions
      this.dataType === 'password' ? this.dataType = 'text' : this.dataType = 'password'
    }
  },
}
</script>

<style lang="sass">

.BaseInput
  +flex($direction: column)
  $block: &
  flex-shrink: 0
  position: relative

  .fa-view
    bottom: 0
    padding: rem(11px) rem(16px)
    position: absolute
    right: 0

  &__input
    background-color: $white
    border: $border
    border-radius: $border-radius
    color: #3E3F42
    outline: none
    padding: rem(7px) 1rem
    transition: all .15s ease 0s
    width: 100%

    &:invalid
      box-shadow: none

    &:focus,
    &:hover
      border-color: $branding

    &::placeholder
      color: #AFB1B5
      font-size: inherit

    &:disabled
      color: $text-light

      &:focus,
      &:hover
        border-color: $borders

    &--error
      border-color: $danger

  &__label,
  &__label span
    color: #9EA0A5
    font-size: rem(12px)
    line-height: rem(18px)
    margin-bottom: rem(7px)
    user-select: none
</style>