import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Debtor = new Entity('debtors')

Debtor.bulkUpload = async (params) => {
  return (await bobtailAPI()).post(`${Debtor.baseUrl}/additional-operations/upload`, params)
}

Debtor.getUnknown = async () => {
  return (await bobtailAPI()).get(`${Debtor.baseUrl}/additional-operations/get-unknown-debtor`)
}

Debtor.getUnknownDebtorId = async () => {
  return (await (await bobtailAPI()).get(`${Debtor.baseUrl}/additional-operations/get-unknown-debtor`)).data.id
}

Debtor.mergeDebtors = async (params) => {
  return (await bobtailAPI()).post(`${Debtor.baseUrl}/additional-operations/merge`, {
    ...params
  })
}

Debtor.reprocessUpload = async (params) => {
  return (await bobtailAPI()).post(`${Debtor.baseUrl}/additional-operations/rerunrules`, params)
}

Debtor.resetDebtorHistoryMetadata = async (params = {}) => {
  return (await bobtailAPI()).post(`${Debtor.baseUrl}/additional-operations/reset-debtor-history-metadata`, params)
}

export default Debtor