// This is copied to /marketing-website/utils/api/api-service
// If it needs updated for any reason, please update /marketing-website if applicable
import bobtailAPI from '../axiosConfig'

export default class ApiService {
  static baseUrl = '/api/services'

  static async checkPhoneConnected (params) {
    return (await bobtailAPI()).post(`${ApiService.baseUrl}/phone-number/validate`, {
      ...params
    })
  }
}