const store = {
  batchDate: null,
  client: {},
  clientBalance: '',
  clients: [],
  debtor: {},
  debtors: [],
  invoice: null,
  invoices: {
    invoices: [],
    limit: 100,
    sum: null,
    total: 0,
  },
  mobile: {
    isMobile: false,
  },
  queue: {
    create: [],
    delete: [],
    update: {}
  },
  temp: {
    alert: {
      message: null,
      show: {
        next: true,
        previous: true,
      },
      visible: false,
    },
    clientWithoutBankModal: false,
    notifyReason: null,
    possibleDuplicates: [],
    recentCreditChecks: [],
    unknownDebtor: '',
  },
  universalSearchResults: {
    invoices: [],
    limit: 100,
    sum: null,
    total: null,
  },
  user: {},
}

export default store
