// disable any console logs in production
export const CLog = (...args) => {
  if (process.env.VUE_APP_ENV !== 'production') console.log(...args)
}

export const CDir = (...args) => {
  if (process.env.VUE_APP_ENV !== 'production') console.dir(...args)
}

export const CError = (...args) => {
  if (process.env.VUE_APP_ENV !== 'production') console.error(...args)
}

export const CWarn = (...args) => {
  if (process.env.VUE_APP_ENV !== 'production') console.warn(...args)
}

export default {
  methods: {
    CDir,
    CError,
    CLog,
    CWarn
  }
}