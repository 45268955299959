// This is copied to /marketing-website/utils/api/api-service
// If it needs updated for any reason, please update /marketing-website if applicable
// Packages
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { v4 as uuidv4 } from 'uuid'
// Helpers
import { getCurrentJWTToken } from './cognito'
import { CError } from './consoleMixin'

const BASE_API = async () => {
  const jwtToken = await getCurrentJWTToken()
  return {
    headers: {
      authorization: jwtToken,
      indempotency: uuidv4(),
      'content-type': 'application/json',
    },
    baseURL: process.env.VUE_APP_BASE_URL
  }
}

// we don't necessarily want to hit the same base url every time, if we use other apis
// this is a place we can initialize the headers we send everywhere as well
const bobtailAPI = async () => {
  const api = axios.create({
    ...await BASE_API(),
  })
  const userAgent = window.navigator.userAgent
  // If user is on Edge or IE, return now
  if (userAgent.includes('Trident') || userAgent.includes('Edge')) {
    return api
  }
  // Breaks login on Edge/IE
  api.interceptors.request.use((config) => {
    config.metadata = { startTime: new Date() }
    // If a controlledClient currently exists, send their information to the backend
    const controlledClient = localStorage.getItem('controlledClient')
    if (controlledClient) {
      if (!config.params) {
        config.params = { controlledClientUser: JSON.parse(controlledClient).id }
      } else {
        config.params.controlledClientUser = JSON.parse(controlledClient).id
      }
    }

    return config
  }, (error) => {
    CError('Axios request interceptor error', error)
    return Promise.reject(error)
  })
  api.interceptors.response.use(
    (response) => {
      response.config.metadata.endTime = new Date()
      response.duration = response.config.metadata.endTime - response.config.metadata.startTime
      // CLog('request and time:', response.config.url, response.duration)
      try {
        // remove any id numbers from the end since we're grouping by API call type
        const urlSplits = response.config.url.split('/');
        const last = urlSplits[urlSplits.length - 1]
        const url = isNaN(last)
          ? response.config.url
          : response.config.url.split(`/${last}`)[0]

        let role = 'UNAUTHENTICATED';
        const user = JSON.parse(localStorage.getItem('user'))
        if (user && user.employee) role = 'EMPLOYEE'
        if (user && user.client) role = 'CLIENT'
        if (window.ga) {
          window.ga('send', {
            hitType: 'timing',
            timingCategory: `API REQUEST ${role} ${response.config.method.toUpperCase()}`,
            timingVar: url,
            timingValue: response.duration,
            label: url
          })
        }
      } catch (err) {
        CError('Error with google analytics', err)
      }
      return response
    },
    (error) => {
      CError('GENERAL ERROR:', error)
      error.config.metadata.endTime = new Date()
      error.duration = error.config.metadata.endTime - error.config.metadata.startTime
      CError('AXIOS ERROR:', error.config, error.response)

      try {
        CError('Stringified:', JSON.stringify(error))
      } catch (err) {
        CError('Error with stringify error')
      }
      if (error.response && error.response.status === 401) {
        if (
          error.response.data.message.includes === 'User not found'
          || error.response.data.name === 'AuthError')
        {
          window.open('/login', '_self')
        }
        // Once the BE is in maintenance mode, all authentication requests for non-masters on all
        // requests will fail, and we'll deliver a special name for the error to route users to
        // maintenance
        if (error.response.data.name === 'MaintenanceError') {
          window.open('/maintenance', '_self')
        }
      }
      return Promise.reject(error)
    }
  );
  const retryDelay = (retryNumber = 0) => {
    // eslint-disable-next-line no-restricted-properties
    const seconds = Math.pow(2, retryNumber) * 1000;
    const randomMs = 1000 * Math.random();
    return seconds + randomMs;
  };
  axiosRetry(api, {
    retries: 3,
    retryDelay,
    // retry on Network Error code or on "Network Error" message
    retryCondition: (response) => {
      try {
        return axiosRetry.isNetworkError(response) || response.toString().includes('Network Error');
      } catch (error) {
        CError('Axios retry error', error);
      }
      return false;
    }
  });
  return api
}

export default bobtailAPI
