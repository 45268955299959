import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const ClientDebtorAssignment = new Entity('clientdebtorassignments')

ClientDebtorAssignment.getNOAEmails = async (id) => {
  return (await bobtailAPI()).get(`${ClientDebtorAssignment.baseUrl}/noa-emails/${id}`)
}

ClientDebtorAssignment.resendNOA = async (params) => {
  return (await bobtailAPI()).post(`${ClientDebtorAssignment.baseUrl}/resend-noa`, {
    ...params
  })
}

ClientDebtorAssignment.sendAssignmentEmail = async (params) => {
  return (await bobtailAPI()).post(`${ClientDebtorAssignment.baseUrl}/send-release-letter/${params.id}`, {
    ...params
  })
}

export default ClientDebtorAssignment