import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Buyout = new Entity('buyouts')

Buyout.deleteAll = async (params) => {
  return (await bobtailAPI()).post(`${Buyout.baseUrl}/all`, {
    ...params
  })
}

Buyout.postBatchCreate = async (params) => {
  return (await bobtailAPI()).post(`${Buyout.baseUrl}/batch-create`, {
    ...params
  })
}
Buyout.deleteAll = async (params) => {
  return (await bobtailAPI()).delete(`${Buyout.baseUrl}/all`, {
    ...params
  })
}
Buyout.approveAllInvoices = async (params) => {
  return (await bobtailAPI()).post(`${Buyout.baseUrl}/approve-all`, {
    ...params
  })
}

export default Buyout;