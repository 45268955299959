<template>
  <nav
    v-if="visible"
    :class="{ 'Nav__desktop': !mobile.isMobile, 'Nav__mobile': mobile.isMobile }"
  >
    <!-- DESKTOP -->
    <navigation-desktop
      v-if="!mobile.isMobile"
      :is-master="isMaster"
      :nav-items="navItems"
      :user-is-client="!!(user && user.client)"
    />

    <!-- MOBILE -->
    <navigation-mobile
      v-else
      :nav-items="navItems"
    />
  </nav>
</template>

<script>
// Helpers
import initNavRoutes from '../utils/initNavRoutes'
// Components
import NavigationDesktop from './navigation-desktop.vue'
import NavigationMobile from './navigation-mobile.vue'

/*
 * Navigation must accomplish 3 tasks:
 * A. Set the routes based on the logged in user
 * A. Set the navigation items based on the type of user (client vs employee)
 *    1. Must set nav items to an empty array if no user is logged in
 * B. Set the active class and expanded to true on any click nav item's, or its parent
 * C. On reload, set the active class and expanded on the item with a name matching the path name
 */

export default {
  name: 'Navigation',

  components: {
    NavigationDesktop,
    NavigationMobile,
  },

  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },

  created () {
    this.setVisibility()

    if (this.user) {
      // Init routes
      this.navItems = initNavRoutes(this.user, this.mobile.isMobile)
      this.setExpandedOnLoad()
      // Set isMaster for navigation-desktop
      if (this.user.employee && this.user.employee.is_master) this.isMaster = true
    }
    else {
      this.navItems = []
    }
  },

  data () {
    return {
      isMaster: false, // Used for styleguide and release-notes route
      navItems: null,
      visible: true,
    }
  },

  watch: {
    mobile (newVal, oldVal) {
      if (oldVal.isMobile !== newVal.isMobile) {
        this.navItems = initNavRoutes(this.user, this.mobile.isMobile)
      }
    },

    $route (newRoute) {
      // This replaces the browser tab title
      // Do not want a space and - if there isn't a title
      document.title = `Bobtail${newRoute.meta.title.length ? ` - ${newRoute.meta.title}` : ''}`
      // When watching the route, newRoute is far more accurate for the "current" route, or
      // at least the route we want when checking for navigation's visibility
      // By checking the route we're GOING to, it's a faster, more predictable method
      // of controlling when the navigation is displayed
      this.setVisibility(newRoute)
    },

    user () {
      // update routes when props user changes (on login)
      this.navItems = initNavRoutes(this.user, this.mobile.isMobile)
      this.setExpandedOnLoad()
    },
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    currentParentPath () {
      // Store the parent path, i.e. :/invoices/pending" is stored as "invoices"
      // window.location is more reliable than this.$route...
      const indexOfLastBackslash = window.location.pathname.lastIndexOf('/')
      return window.location.pathname.slice(indexOfLastBackslash + 1)
    },

    setExpandedOnLoad () {
      // Don't need to expand on mobile
      if (this.mobile.isMobile) return
      let routeName = this.currentParentPath()

      // These are sub-options within the invoices navigation option
      if (['pending', 'approved', 'paid', 'declined'].includes(routeName)) {
        routeName = 'invoices'
      }

      // If one of the invoice pages is the active route, expand the invoices nav items
      if (routeName === 'invoices') {
        const indexOfItemMatched = this.navItems.findIndex((item) => {
          return routeName === item.to.name
        })
        this.$set(this.navItems[indexOfItemMatched], 'expanded', true)
      }
    },

    setVisibility (newRoute = null) {
      const route = newRoute ? newRoute.name : this.$route.name
      // Navigation doesn't display on these pages
      this.visible = ![
        'admin-login',
        'invoice-details',
        'login',
        'maintenance',
        'password-reset',
        'privacy',
        'release-notes',
        'set-password',
        'styleguide',
        'terms',
        null
      ].includes(route)
    },
  },
}
</script>

<style lang="sass">
.Nav

  &__desktop
    $desktopNavWidth: rem(255px)
    +flex($direction: column, $basis: $desktopNavWidth, $grow: 0, $shrink: 0)
    padding: 3rem 2rem 1rem 0

  &__mobile
    left: 0
    position: fixed
    top: 0
    width: 100%
    // There's nothing greater than 4 besides base-modal
    z-index: 5
</style>
