var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickedOutside,
          expression: "clickedOutside"
        }
      ],
      staticClass: "BaseUserSearch column",
      attrs: { "data-cy": "debtor-search" }
    },
    [
      _vm.label
        ? _c(
            "label",
            {
              staticClass:
                "BaseUserSearch__label fc-light fs-12 uppercase no-select",
              attrs: { for: _vm.labelFor }
            },
            [_vm._t("label")],
            2
          )
        : _vm._e(),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.input,
            expression: "input",
            modifiers: { trim: true }
          }
        ],
        ref: "BaseUserSearch__input",
        class: [
          "BaseUserSearch__input bg-white fs-14",
          { "BaseUserSearch__input--error": !_vm.valid && !_vm.debtor },
          { "BaseUserSearch__input--w-dropdown": _vm.dropdown }
        ],
        attrs: {
          autocomplete: "off",
          "data-cy": "debtor-search-input",
          id: _vm.labelFor,
          placeholder: _vm.placeholder
        },
        domProps: { value: _vm.input },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.input = $event.target.value.trim()
            },
            function($event) {
              _vm.input = $event.target.value.trim()
            }
          ],
          keydown: function($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 40) {
              return null
            }
            $event.preventDefault()
            return _vm.moveToResults($event)
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      }),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        !_vm.valid && !_vm.debtor
          ? _c("label", { staticClass: "fc-red fs-12" }, [
              _vm._v("\n      " + _vm._s(_vm.instructions) + "\n    ")
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "BaseUserSearch__list-wrapper" }, [
        _vm.results.length && _vm.dropdown
          ? _c(
              "ul",
              {
                ref: "BaseUserSearch__list",
                staticClass: "BaseUserSearch__list bg-white",
                on: {
                  keydown: [
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 40
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveDownResults($event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        $event.keyCode !== 38
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.moveUpResults($event)
                    }
                  ]
                }
              },
              [
                _vm._l(_vm.results, function(debtorResult, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "BaseUserSearch__list-item column",
                      attrs: {
                        "data-cy": "debtor-search-result-" + index,
                        tabindex: "0"
                      },
                      on: {
                        click: function($event) {
                          return _vm.select(debtorResult, $event)
                        },
                        keypress: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "space", 32, $event.key, [
                              " ",
                              "Spacebar"
                            ]) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.select(debtorResult, $event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row row--align-center" },
                        [
                          debtorResult.name === "debtor not found"
                            ? [_vm._m(0, true)]
                            : [
                                _c(
                                  "span",
                                  {
                                    class: _vm.debtorRatingStyles(debtorResult)
                                  },
                                  [_vm._v(_vm._s(debtorResult.display_rating))]
                                ),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "span",
                                    { staticClass: "fs-14 uppercase" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            debtorResult.name
                                              ? _vm.filterAlgoliaDebtorName(
                                                  debtorResult.name
                                                )
                                              : ""
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "fc-light fs-12" },
                                    [
                                      _vm._v(
                                        _vm._s(debtorResult.city) +
                                          ", " +
                                          _vm._s(debtorResult.state)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "fc-light fs-12" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            debtorResult.broker
                                              ? "MC " + debtorResult.broker.mc
                                              : ""
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                        ],
                        2
                      )
                    ]
                  )
                }),
                _vm.page < _vm.maxSearchPages
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "\n          BaseUserSearch__list-item BaseUserSearch__load-more-results\n          column fc-light fs-12 fw-med uppercase\n        ",
                        attrs: { tabindex: "0" },
                        on: {
                          click: _vm.loadMoreResults,
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar"
                              ]) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.loadMoreResults($event)
                          }
                        }
                      },
                      [_vm._v("\n        Load More Results\n      ")]
                    )
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row row--justify-center" }, [
      _c("span", { staticClass: "'fs-14'" }, [_vm._v("Debtor Not Found")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }