export default {
  methods: {
    alertError (message, title = null) {
      this.$Toastr.error(message, title)
    },

    alertInfo (message, title = null) {
      this.$Toastr.info(message, title)
    },

    alertWarning (message, title = null) {
      this.$Toastr.warning(message, title)
    },

    alertSuccess (message, title = null) {
      this.$Toastr.success(message, title)
    },

    progressFail () {
      this.$Progress.fail()
    },

    progressFinish () {
      this.$Progress.finish()
    },

    progressStart () {
      this.$Progress.start()
    },

    requestFailure (toastr) {
      this.$Progress.fail()
      this.$Toastr.error(toastr.message, toastr.title ? toastr.title : 'Request error')
    },

    requestInfo (toastr) {
      this.$Progress.finish()
      this.$Toastr.info(toastr.message, toastr.title ? toastr.title : 'Request info')
    },

    requestSuccess (toastr) {
      this.$Progress.finish()
      this.$Toastr.success(toastr.message, toastr.title ? toastr.title : 'Request success')
    },
  }
}