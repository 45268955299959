// Dependencies
import Vue from 'vue'
import Vuex from 'vuex'
// Helpers
import { transformRawInvoice } from '../utils/helpers'
// Entry imports
import mutations from './mutations'
import store from './store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { ...store },
  actions: {
    // Keeping as a semantic example in case I need to create an action
    // SEARCH_INVOICES: ({ commit }, payload) => {
    //   actions.SEARCH_INVOICES({ commit }, payload)
    // },
  },
  getters: {
    alert: (state) => {
      return state.temp.alert
    },
    batchDate: (state) => {
      return state.batchDate
    },
    client: (state) => {
      return state.client
    },
    clientBalance: (state) => {
      return state.clientBalance
    },
    clients: (state) => {
      return state.clients
    },
    clientWithoutBankModal: (state) => {
      return state.temp.clientWithoutBankModal
    },
    debtor: (state) => {
      return state.debtor
    },
    debtors: (state) => {
      return state.debtors
    },
    invoice: (state) => {
      return state.invoice
    },
    invoices: (state) => {
      return state.invoices
    },
    mobile: (state) => {
      return state.mobile
    },
    notifyReason: (state) => {
      return state.temp.notifyReason
    },
    queue: (state) => {
      return state.queue
    },
    universalSearchResults: (state) => {
      return state.universalSearchResults
    }
  },
  mutations: {
    CLEAR_CLIENT: (state) => {
      state.client = {}
    },

    CLEAR_DEBTOR: (state) => {
      state.debtor = {}
    },

    CLEAR_DELETE_QUEUE: (state) => {
      // Keep it reactive
      state.queue = Object.assign(state.queue, { delete: [] })
    },

    CLEAR_INVOICE: (state) => {
      state.invoice = null
    },

    CLEAR_INVOICES: (state) => {
      state.invoices = Object.assign({}, {
        invoices: [],
        limit: 100,
        sum: null,
        total: null,
      })
    },

    CLEAR_SIDEBAR_NOTIFY_REASON: (state) => {
      state.temp = Object.assign({}, state.temp, { notifyReason: null })
    },

    CLEAR_UPDATE_QUEUE: (state) => {
      // Keep it reactive
      state.queue = Object.assign(state.queue, { update: {} })
    },

    CLEAR_UNIVERSAL_SEARCH_RESULTS: (state) => {
      state.universalSearchResults = Object.assign({}, {
        invoices: [],
        limited: 100,
        sum: null,
        total: null,
      })
    },

    DELETE_USER_CREATE_QUEUE: (state, payload) => {
      state.queue.create.splice(state.queue.create.indexOf(payload), 1)
    },

    DELETE_INVOICE: (state, payload) => {
      mutations.DELETE_INVOICE(state, payload)
    },

    DELETE_UNIVERSAL_SEARCH_INVOICE: (state, payload) => {
      mutations.DELETE_UNIVERSAL_SEARCH_INVOICE(state, payload)
    },

    HIDE_ALERT: (state) => {
      state.temp = Object.assign(state.temp, {
        alert: {
          message: null,
          show: {
            backToPending: true,
            next: true,
            previous: true,
          },
          visible: false,
        }
      })
    },

    INSERT_USER_CREATE_QUEUE: (state, payload) => {
      state.queue.create.push(payload)
    },

    INSERT_INVOICE: (state, payload) => {
      mutations.INSERT_INVOICE(state, payload)
    },

    REPLACE_EXISTING_INVOICE: (state, payload) => {
      mutations.REPLACE_EXISTING_INVOICE(state, payload)
    },

    REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE: (state, payload) => {
      mutations.REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE(state, payload)
    },

    SET_ALERT: (state, payload) => {
      state.temp = Object.assign(state.temp, {
        alert: {
          message: payload.message,
          show: {
            backToPending: payload.backToPending || false,
            next: payload.showNext,
            previous: payload.showPrevious,
          },
          visible: true,
        }
      })
    },

    STORE_BATCH_DATE: (state, payload) => {
      state.batchDate = payload
    },

    STORE_CLIENT: (state, payload) => {
      state.client = payload
    },

    STORE_CLIENTS: (state, payload) => {
      state.clients = payload.hits
    },

    STORE_CLIENT_BALANCE: (state, payload) => {
      state.clientBalance = payload
    },

    STORE_CLIENT_WITHOUT_BANK_MODAL: (state, payload) => {
      state.temp = Object.assign({}, state.temp, {
        clientWithoutBankModal: payload,
      })
    },

    STORE_DEBTOR: (state, payload) => {
      state.debtor = payload
    },

    STORE_DEBTORS: (state, payload) => {
      state.debtors = payload.hits
    },

    STORE_INVOICE: (state, payload) => {
      state.invoice = transformRawInvoice(payload)
    },

    STORE_INVOICES: (state, payload) => {
      mutations.STORE_INVOICES(state, payload)
    },

    STORE_IS_MOBILE: (state, payload) => {
      state.mobile = Object.assign({}, state.mobile, {
        isMobile: payload,
      })
    },

    STORE_SIDEBAR_NOTIFY_REASON: (state, payload) => {
      state.temp = Object.assign({}, state.temp, { notifyReason: payload })
    },

    STORE_UNIVERSAL_SEARCH_RESULTS: (state, payload) => {
      mutations.STORE_UNIVERSAL_SEARCH_RESULTS(state, payload)
    },

    STORE_USER: (state, payload) => {
      state.user = payload
    },

    SUBMIT_INVOICE: (state, payload) => {
      mutations.SUBMIT_INVOICE(state, payload)
    },

    UPDATE_STORED_CLIENT: (state, payload) => {
      state.client = Object.assign({}, state.client, payload)
    },

    UPDATE_STORED_DEBTOR: (state, payload) => {
      state.debtor = Object.assign({}, state.debtor, payload)
    },
  }
})
