const routes = {
  client: (user) => {
    return [
      {
        expanded: false,
        icon: 'fa-gallery',
        title: 'dashboard',
        to: {
          name: 'dashboard-client',
          params: { id: user && user.client ? user.client.id : null }
        },
      },
      {
        expanded: false,
        icon: 'fa-database',
        subroutes: [
          {
            title: 'pending',
            to: { name: 'pending' },
          },
          {
            title: 'approved',
            to: { name: 'approved' },
          },
          {
            title: 'declined',
            to: { name: 'declined' },
          },
          {
            title: 'paid',
            to: { name: 'paid' },
          },
        ],
        title: 'invoices',
        to: { name: 'invoices' }
      },
      {
        expanded: false,
        icon: 'fa-clipboard',
        title: 'credit check',
        to: { name: 'credit-check' },
      },
    ]
  },
  employee: () => {
    return [
      {
        expanded: false,
        icon: 'fa-gallery',
        title: 'dashboard',
        to: { name: 'dashboard-bobtail' }
      },
      {
        expanded: false,
        icon: 'fa-database',
        subroutes: [
          {
            title: 'pending',
            to: { name: 'pending' },
          },
          {
            title: 'approved',
            to: { name: 'approved' },
          },
          {
            title: 'declined',
            to: { name: 'declined' },
          },
          {
            title: 'paid',
            to: { name: 'paid' },
          },
        ],
        title: 'invoices',
        to: { name: 'invoices' }
      },
      {
        expanded: false,
        icon: 'fa-clipboard',
        title: 'credit check',
        to: { name: 'credit-check' }
      },
      {
        expanded: false,
        icon: 'fa-user',
        title: 'user management',
        to: { name: 'user-management' }
      },
    ]
  },
  master: () => {
    return [
      {
        expanded: false,
        icon: 'fa-gallery',
        title: 'dashboard',
        to: { name: 'dashboard-bobtail' }
      },
      {
        expanded: false,
        icon: 'fa-database',
        subroutes: [
          {
            to: { name: 'pending' },
            title: 'pending'
          },
          {
            to: { name: 'approved' },
            title: 'approved'
          },
          {
            to: { name: 'declined' },
            title: 'declined'
          },
          {
            to: { name: 'paid' },
            title: 'paid'
          },
          {
            title: 'buyouts',
            to: { name: 'buyouts' }
          },
        ],
        title: 'invoices',
        to: { name: 'invoices' }
      },
      {
        expanded: false,
        icon: 'fa-clipboard',
        title: 'credit check',
        to: { name: 'credit-check' }
      },
      {
        expanded: false,
        icon: 'fa-user',
        title: 'user management',
        to: { name: 'user-management' }
      },
      {
        expanded: false,
        icon: 'fa-money',
        title: 'admin',
        to: { name: 'admin' }
      },
    ]
  },
  mobile: {
    client: (user) => {
      return [
        {
          expanded: false,
          icon: 'fa-inbox',
          title: 'submit invoice',
          to: { name: 'submit-invoice' },
        },
        {
          expanded: false,
          icon: 'fa-dollar-sign',
          title: 'transfers',
          to: {
            name: 'dashboard-client-transfers',
            params: { id: user.client.id },
          },
        },
        {
          expanded: false,
          icon: 'fa-folded-page',
          title: 'pending invoices',
          to: { name: 'pending' },
        },
        {
          expanded: false,
          icon: 'fa-approved-invoice',
          title: 'approved invoices',
          to: { name: 'approved' },
        },
        {
          expanded: false,
          icon: 'fa-database',
          title: 'balance history',
          to: {
            name: 'dashboard-client-balance-history',
            params: { id: user.client.id }
          },
        },
        {
          expanded: false,
          icon: 'fa-credit-check',
          title: 'credit check',
          to: { name: 'credit-check' },
        },
        {
          expanded: false,
          icon: 'fa-payment',
          title: 'bank accounts',
          to: {
            name: 'dashboard-client-banking',
            params: { id: user.client.id },
          },
        },
      ]
    },
    employee: () => {
      return [
        {
          expanded: false,
          icon: 'fa-inbox',
          title: 'submit invoice',
          to: { name: 'submit-invoice' },
        },
        // {
        //   expanded: false,
        //   icon: 'fa-dollar-sign',
        //   title: 'transfers',
        //   to: {
        //     name: 'dashboard-employee-transfers',
        //     params: { id: user.employee.id },
        //   },
        // },
        {
          expanded: false,
          icon: 'fa-folded-page',
          title: 'pending invoices',
          to: { name: 'pending' },
        },
        {
          expanded: false,
          icon: 'fa-approved-invoice',
          title: 'approved invoices',
          to: { name: 'approved' },
        },
        // {
        //   expanded: false,
        //   icon: 'fa-database',
        //   title: 'balance history',
        //   to: {
        //     name: 'dashboard-employee-balances',
        //     params: { id: user.employee.id }
        //   },
        // },
        {
          expanded: false,
          icon: 'fa-credit-check',
          title: 'credit check',
          to: { name: 'credit-check' },
        },
      ]
    },
    master: () => {
      return [
        {
          expanded: false,
          icon: 'fa-inbox',
          title: 'submit invoice',
          to: { name: 'submit-invoice' },
        },
        // {
        //   expanded: false,
        //   icon: 'fa-dollar-sign',
        //   title: 'transfers',
        //   to: {
        //     name: 'dashboard-employee-transfers',
        //     params: { id: user.employee.id },
        //   },
        // },
        {
          expanded: false,
          icon: 'fa-folded-page',
          title: 'pending invoices',
          to: { name: 'pending' },
        },
        {
          expanded: false,
          icon: 'fa-approved-invoice',
          title: 'approved invoices',
          to: { name: 'approved' },
        },
        // {
        //   expanded: false,
        //   icon: 'fa-database',
        //   title: 'balance history',
        //   to: {
        //     name: 'dashboard-employee-balances',
        //     params: { id: user.employee.id }
        //   },
        // },
        {
          expanded: false,
          icon: 'fa-credit-check',
          title: 'credit check',
          to: { name: 'credit-check' },
        },
      ]
    },
  },
}

const initNavRoutes = (user, isMobile) => {
  let userType
  if (user.client) userType = 'client'
  if (user.employee) {
    if (user.employee.is_master) userType = 'master'
    else userType = 'employee'
  }
  if (isMobile) return routes.mobile[userType](user)
  return routes[userType](user)
}

export default initNavRoutes
