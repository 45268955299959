import bobtailAPI from '../axiosConfig' // Axios custom instance

export default class Entity {
  baseUrl

  constructor (url) {
    this.baseUrl = `/api/${url}`
  }

  async create (params) {
    return (await bobtailAPI()).post(this.baseUrl, {
      ...params
    })
  }

  async delete (id) {
    return (await bobtailAPI()).delete(`${this.baseUrl}/${id}`)
  }

  async get (id) {
    return (await bobtailAPI()).get(`${this.baseUrl}/${id}`)
  }

  async history (params) {
    // can get history by a specific id or of everything
    const historyUrl = params.id ? `${this.baseUrl}/${params.id}/history` : `${this.baseUrl}/history`
    return (await bobtailAPI()).get(historyUrl, {
      params
    })
  }

  async search (params, searchLimit = null) {
    const defaultSearchLimit = searchLimit || 25
    return (await bobtailAPI()).get(`${this.baseUrl}/search`, {
      params: {
        hitsPerPage: defaultSearchLimit,
        ...params
      }
    })
  }

  async queryList (params, searchLimit = null) {
    const defaultSearchLimit = searchLimit || params.limit || 25
    return (await bobtailAPI()).get(this.baseUrl, {
      params: {
        // if the limit is 'all', set limit to null to include everything
        limit: defaultSearchLimit !== 'all'
          ? defaultSearchLimit
          : null,
        ...params
      },
    })
  }

  async statistics (params) {
    return (await bobtailAPI()).get(`${this.baseUrl}/statistics`, {
      params
    })
  }

  async uniqueField (params) {
    return (await bobtailAPI()).get(`${this.baseUrl}/uniquefield`, {
      params
    })
  }

  async update (params) {
    return (await bobtailAPI()).put(`${this.baseUrl}/${params.id}`, {
      ...params
    })
  }
}