var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.client.id
    ? _c(
        "div",
        { staticClass: "UserProfile" },
        [
          _c(
            "div",
            {
              staticClass:
                "UserProfile__control-btn-container row row--justify-end"
            },
            [
              (_vm.user.employee
              ? _vm.user.employee.is_master
              : false)
                ? _c(
                    "base-button",
                    {
                      staticClass: "fc-white bg-blue fs-14",
                      on: { click: _vm.assumeClientControl }
                    },
                    [_vm._v("\n      Control Client\n    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("user-profile-navigation", {
            attrs: {
              id: _vm.id,
              "page-p-o-v": "client",
              routes: _vm.routes,
              user: _vm.client,
              "user-is-client": _vm.userIsClient
            }
          }),
          _c(
            "transition",
            { attrs: { name: "Transition__opacity-fade" } },
            [
              !_vm.getClientInProgress
                ? _c("router-view", { staticClass: "UserProfile__router-view" })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }