import Entity from './entity'
import bobtailAPI from '../axiosConfig'

const QuickbooksSync = new Entity('quickbookssync')


QuickbooksSync.syncOneJournalEntry = async (params) => {
  return (await bobtailAPI()).post(`${QuickbooksSync.baseUrl}/sync-one-journal-entry`, params)
}

export default QuickbooksSync
