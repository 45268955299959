var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseModal" }, [
    _c("div", { staticClass: "BaseModal__bg" }),
    _c(
      "div",
      { ref: "BaseModal__content", staticClass: "BaseModal__content column" },
      [
        _c(
          "label",
          {
            class: "fs-20 fw-med mb-24 " + _vm.labelClass,
            attrs: { "data-cy": "base-modal-label-slot" }
          },
          [_vm._t("label")],
          2
        ),
        _vm._t("input"),
        _c(
          "div",
          { class: "row mt-24 " + _vm.actionsClass },
          [_vm._t("actions")],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }