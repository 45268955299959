<template>
  <div class="NavDesktop">
    <router-link
      :to="{ name: 'home' }"
      class="NavDesktop__logo"
      tag="a"
    />
    <!-- NAV ITEMS -->
    <div
      v-for="(route, index) in componentNavItems"
      @click="openRoute(index)"
      :key="index"
    >
      <router-link
        :to="route.to"
        :id="`Testing__${route.title.replace(/ /g, '')}`"
        active-class="NavDesktop__route--selected fc-blue"
        class="NavDesktop__route row row--align-center fs-14 fw-med uppercase"
        tag="a"
      >
        <i :class="`fa ${route.icon} fa-28 fa-3 fa--none`" />
        {{ route.title }}
      </router-link>

      <transition name="NavDesktop__fade">
        <div
          v-if="route.subroutes && route.expanded"
          class="NavDesktop__subroutes"
        >
          <router-link
            v-for="(subroute, subrouteIndex) in route.subroutes"
            :key="subrouteIndex"
            :to="subroute.to"
            active-class="NavDesktop__subroute--selected"
            class="NavDesktop__subroute fs-14 capitalize"
            tag="a"
          >
            {{ subroute.title }}
          </router-link>
        </div>
      </transition>
    </div>

    <!-- LOGOUT -->
    <router-link
      :to="{ name: 'log-out' }"
      class="NavDesktop__route NavDesktop__route-logout row row--width-auto fc-light fs-14"
      tag="a"
    >
      LOG OUT
    </router-link>

    <router-link
      v-if="isMaster"
      :to="{ name: 'release-notes' }"
      class="NavDesktop__route NavDesktop__route-release-notes row row--width-auto fc-light fs-14"
      tag="a"
    >
      RELEASE NOTES
    </router-link>

    <router-link
      v-if="isMaster"
      :to="{ name: 'styleguide' }"
      class="NavDesktop__route NavDesktop__route-styleguide row row--width-auto fc-light fs-14"
      tag="a"
    >
      STYLEGUIDE
    </router-link>

    <!-- ACCOUNT MANAGER (client and desktop ONLY) -->
    <!-- The Mobile version is in mobile-home.vue -->
    <div
      v-if="userIsClient && accountManager"
      class="NavDesktop__account-manager bg-white column"
    >
      <label class="fw-med fc-mid fs-14 mb-8 no-select no-wrap">
        YOUR ACCOUNT REP:
      </label>
      <label class="fc-dark fs-12 mb-4 no-select">
        <i class="fa fa-user fc-light" />{{
          `${accountManager.first_name.trim()} ${accountManager.last_name.trim()}`
        }}
      </label>
      <a
        class="fc-blue fs-12 mb-4 row row--align-center no-wrap"
        :href="`mailto:${accountManager.user.email}`"
      >
        <i class="fa fa-mail fc-light" />
        {{ accountManager.user.email }}
      </a>
      <label
        class="fc-dark fs-12 row row--align-center no-wrap"
      >
        <i class="fa fa-phone" />
        (410) 204-2084 {{ accountManager.extension ? `ext ${accountManager.extension}` : '' }}
      </label>
    </div>
  </div>
</template>

<script>
// Helpers
import {
  Employee
} from '../utils/api'

export default {
  name: 'NavDesktop',

  props: {
    // Used to display styleguide and release notes links
    isMaster: {
      type: Boolean,
      required: false,
      default: false,
    },
    navItems: {
      type: Array,
      required: true,
    },
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  async created () {
    if (this.userIsClient) this.getClientAccountManager()
  },

  data () {
    return {
      accountManager: null,
      componentNavItems: this.navItems,
    }
  },

  methods: {
    async getClientAccountManager () {
      try {
        const client = JSON.parse(localStorage.getItem('user')).client
        this.accountManager = (await Employee.get(client.account_manager_id)).data
      } catch (error) {
        this.captureSentryEvent(
          'Client Account Manager Fetch Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError('Error loading account manager:', error)
      }
    },

    openRoute (index) {
      this.componentNavItems.forEach(item => this.$set(item, 'expanded', false))
      this.$set(this.componentNavItems[index], 'expanded', true)
    },
  },
}
</script>

<style lang="sass">
.NavDesktop
  $block: &

  .fa
    font-size: rem(20px)
    width: rem(20px)

  &__account-manager
    border: $border
    border-radius: $border-radius
    margin-top: rem(56px)
    padding: rem(17px)

    .fa
      margin-right: rem(8px)

    .fa-user
      font-size: rem(19px)

    .fa-mail
      font-size: rem(14px)

    .fs-phone
      font-size: rem(18px)

  &__logo
    background-image: url('../assets/logo.png')
    background-position: left
    background-repeat: no-repeat
    background-size: contain
    display: block
    height: rem(37px)
    margin-bottom: 1.5rem
    width: 100%

  &__route
    outline: none
    padding: rem(10px) 0
    white-space: nowrap

    &--selected
      .fa
        color: $branding

      #{$block}__route-link
        color: $branding

  &__route-logout
    opacity: .6

  &__route-release-notes,
  &__route-styleguide
    opacity: 0

  &__subroute
    color: $text-light
    height: rem(30px)
    outline: none
    padding-left: rem(47px)

    &--selected
      margin-left: rem(-8px)
      color: $text-dark
      &::before
        content: '\2022'
        color: $branding

  &__subroutes
    +flex($direction: column)

  @media #{$tablet-landscape-and-up}
    .fa
      margin-right: rem(22px)

    &__route-logout,
    &__route-release-notes,
    &__route-styleguide
      margin-left: rem(42px)

</style>