import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const ClientDocument = new Entity('clientdocuments')

ClientDocument.sendDocument = async (params) => {
  return (await bobtailAPI()).post(`${ClientDocument.baseUrl}/send-document/${params.id}`, {
    ...params,
  })
}

export default ClientDocument