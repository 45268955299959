/* eslint-disable max-len */
// ^ This prevents errors in the `component` property of each route config
import Vue from 'vue'
import Router from 'vue-router'
// Helpers
import {
  getCurrentJWTToken,
  globalSignOut,
} from './utils/cognito'
import { CLog } from './utils/consoleMixin'
import {
  Debtor,
  Tenant,
} from './utils/api'

Vue.use(Router)

let user = JSON.parse(localStorage.getItem('user'))
const isMobile = () => window.matchMedia('(max-width: 1024px)').matches
const maintenanceMode = async () => (await Tenant.fetchMaintenanceMode()).data

const userType = {
  isClient: () => {
    if (user && user.client) return true
    return false
  },
  isEmployee: () => {
    if (user && user.employee) return true
    return false
  },
  isMaster: () => {
    if (user && user.employee && user.employee.is_master) return true
    return false
  },
}

/*
 * Meta fields:
 * (ex. adding `requiresClient` means the user must have a client's or higher level of permission)
 * A. User must be a client: `requiresClient: true`
 * B. User must be an employee: `requiresEmployee: true`
 * C. User must be a master employee: `requiresMaster: true`
 */
const router = new Router({
  mode: 'history',
  routes: [
    {
      meta: {
        maintenanceFriendly: true,
        mobileFriendly: false,
        requiresAuth: true,
        requiresMaster: true,
        title: '',
      },
      name: 'admin',
      path: '/admin',
      props: (route) => ({
        ...route.params,
      }),
      redirect: { name: 'admin-debtor-upload' },
      component: () => import(/* webpackChunkName: "admin" */ './views/admin/admin.vue'),
      children: [
        {
          meta: {
            title: 'Admin Balances',
          },
          name: 'admin-balances',
          path: 'admin-balances',
          props: true,
          component: () => import(/* webpackChunkName: "admin-rules" */'./views/admin/admin-balances.vue'),
        },
        {
          meta: {
            title: 'Debtor Upload',
          },
          name: 'admin-debtor-upload',
          path: 'admin-debtor-upload',
          props: true,
          component: () => import(/* webpackChunkName: "debtor-upload" */'./views/admin/admin-debtor-upload.vue'),
        },
        {
          meta: {
            title: 'Maintenance Mode',
          },
          name: 'admin-maintenance',
          path: 'admin-maintenance',
          props: true,
          component: () => import(/* webpackChunkName: "admin-maintenance" */'./views/admin/admin-maintenance.vue'),
        },
      ]
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Admin Login',
      },
      name: 'admin-login',
      path: '/admin-login',
      props: true,
      component: () => import(/* webpackChunkName: "log-in" */ './views/auth/login.vue')
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresMaster: true,
        title: 'Buyouts',
      },
      name: 'buyouts',
      path: '/buyouts',
      props: true,
      component: () => import(/* webpackChunkName: "buyouts" */ './views/buyouts.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'home',
      path: process.env.VUE_APP_ENV === 'staging' ? '/home' : '/',
      redirect: () => {
        if (isMobile()) return { name: 'home-mobile' }
        return { name: 'pending' }
      },
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'home-mobile',
      path: '/home-mobile',
      component: () => import(/* webpackChunkName: "mobileHome" */ './views/mobile-home'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'client',
      path: '/client/:id',
      props: (route) => ({
        ...route.params,
        userIsClient: userType.isClient(),
      }),
      redirect: { name: 'client-activity' },
      component: () => import(/* webpackChunkName: "client" */ './views/client/profile/client.vue'),
      children: [
        {
          meta: {
            requiresEmployee: true,
            title: 'Client Profile',
          },
          name: 'client-profile',
          path: 'profile',
          component: () => import(/* webpackChunkName: "client-profile" */ './views/client/profile/client-profile.vue'),
        },
        {
          meta: {
            requiresEmployee: true,
            title: 'Client Contacts',
          },
          name: 'client-contacts',
          path: 'contacts',
          props: () => ({
            pagePOV: 'client',
          }),
          component: () => import(/* webpackChunkName: "user-profile-contacts" */ './components/user-profile-contacts.vue'),
        },
        {
          meta: {
            title: 'Client Activity',
          },
          name: 'client-activity',
          path: 'activity',
          props: () => ({
            pagePOV: 'client',
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "client-activity" */ './components/user-activity.vue'),
        },
        {
          meta: {
            requiresEmployee: true,
            title: 'Processing Notes',
          },
          name: 'client-processing-notes',
          path: 'notes',
          component: () => import(/* webpackChunkName: "client-docs" */ './views/client/profile/client-processing-notes.vue'),
        },
        {
          meta: {
            requiresEmployee: true,
            title: 'Client Docs',
          },
          name: 'client-docs',
          path: 'docs',
          component: () => import(/* webpackChunkName: "client-docs" */ './views/client/profile/client-docs.vue'),
        },
        {
          meta: {
            requiresEmployee: true,
            title: 'Client Assignments',
          },
          name: 'client-assignments',
          path: 'assignments',
          props: () => ({
            pagePOV: 'client',
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "user-profile-assignments" */ './components/user-profile-assignments.vue'),
        },
        {
          meta: {
            requiresEmployee: true,
            title: '',
          },
          name: 'client-data',
          path: 'data',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          redirect: { name: 'client-data-general' },
          component: () => import(/* webpackChunkName: "client-data" */ './views/client/profile/data/client-data.vue'),
          children: [
            {
              meta: {
                title: 'Client General Data',
              },
              name: 'client-data-general',
              path: 'general',
              props: (route) => ({
                ...route.params
              }),
              component: () => import(/* webpackChunkName: "client-data-general" */ './views/client/profile/data/client-data-general.vue'),
            },
            {
              meta: {
                title: 'Client Dilution Data',
              },
              name: 'client-data-dilution',
              path: 'dilution',
              props: (route) => ({
                ...route.params
              }),
              component: () => import(/* webpackChunkName: "client-data-dilution" */ './views/client/profile/data/client-data-dilution.vue'),
            },
            {
              meta: {
                title: 'Client Debtor Data',
              },
              name: 'client-data-debtors',
              path: 'debtors',
              props: (route) => ({
                ...route.params
              }),
              component: () => import(/* webpackChunkName: "client-data-debtors" */ './views/client/profile/data/client-data-debtors.vue'),
            },
          ],
        },
        {
          meta: {
            requiresEmployee: true,
            title: 'Client Status',
          },
          name: 'client-status',
          path: 'status',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "client-status" */ './views/client/profile/client-status.vue'),
        },
      ]
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: 'Create Client',
      },
      name: 'create-client',
      path: '/create-client',
      component: () => import(/* webpackChunkName: "create-client" */ './views/client/create/create-client.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: 'Create Debtor',
      },
      name: 'create-debtor',
      path: '/create-debtor',
      component: () => import(/* webpackChunkName: "create-debtor" */ './views/debtor/create/create-debtor.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresMaster: true,
        title: 'Create Employee',
      },
      name: 'create-employee',
      path: '/create-employee',
      component: () => import(/* webpackChunkName: "create-employee" */ './views/employee/create-edit-employee.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresMaster: true,
        title: 'Edit Employee',
      },
      name: 'edit-employee',
      path: '/edit-employee',
      props: (route) => ({
        ...route.params
      }),
      component: () => import(/* webpackChunkName: "edit-employee" */ './views/employee/create-edit-employee.vue'),
      beforeEnter: (to, from, next) => {
        // if this page is loaded w/o an employee prop, redirect them to user management
        if (!to.params.employee) {
          next({ name: 'user-management' })
        } else {
          next()
        }
      }
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: 'Credit Check',
      },
      name: 'credit-check',
      path: '/credit-check',
      props: (route) => ({
        ...route.params,
        userIsClient: userType.isClient(),
      }),
      component: () => import(/* webpackChunkName: "credit-check" */ './views/credit-check.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'dashboard',
      path: '/dashboard',
      redirect: () => {
        if (userType.isClient()) return { name: 'dashboard-client', params: { id: user.client.id } }
        if (userType.isEmployee()) return { name: 'dashboard-bobtail' }
      },
      props: true,
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'dashboard-client',
      path: '/dashboard-client/:id',
      props: (route) => ({
        ...route.params,
        userIsClient: userType.isClient(),
      }),
      redirect: { name: 'dashboard-client-notifications' },
      component: () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/dashboard.vue'),
      children: [
        // TODO: https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2103
        // {
        //   meta: {
        //     mobileFriendly: false,
        //     title: 'Client Drivers',
        //   },
        //   name: 'dashboard-client-drivers',
        //   path: 'client-drivers',
        //   props: (route) => ({
        //     ...route.params,
        //     userIsClient: userType.isClient(),
        //   }),
        //   component: () => import(/* webpackChunkName: "drivers" */ './views/dashboard/dashboard-client-drivers.vue'),
        // },
        {
          meta: {
            mobileFriendly: false,
            title: 'Client Notifications',
          },
          name: 'dashboard-client-notifications',
          path: 'client-notifications',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
          }),
          component: () => import(/* webpackChunkName: "notifications" */ './views/dashboard/dashboard-client-notifications.vue'),
        },
        {
          meta: {
            title: 'Client Transfers',
          },
          name: 'dashboard-client-transfers',
          path: 'client-transfers',
          props: true,
          component: () => import(/* webpackChunkName: "transfers" */ './views/dashboard/dashboard-client-transfers.vue'),
        },
        {
          meta: {
            title: 'Client Balance History',
          },
          name: 'dashboard-client-balance-history',
          path: 'client-balance-history',
          props: (route) => ({
            ...route.params,
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "balance-history" */ './views/dashboard/dashboard-client-balance-history.vue'),
        },
        {
          meta: {
            title: 'Client Banking',
          },
          name: 'dashboard-client-banking',
          path: 'client-banking',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "banking" */ './views/dashboard/dashboard-client-banking.vue'),
        },
      ],
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: '',
      },
      name: 'dashboard-bobtail',
      path: '/dashboard-bobtail',
      props: (route) => ({
        ...route.params,
      }),
      redirect: { name: 'dashboard-employee-balances' },
      component: () => import(/* webpackChunkName: "employee-dashboard" */ './views/dashboard/dashboard.vue'),
      children: [
        {
          meta: {
            title: 'Bobtail Balances',
          },
          name: 'dashboard-employee-balances',
          path: 'employee-balances',
          props: true,
          component: () => import(/* webpackChunkName: "employee-balances" */'./views/dashboard/dashboard-employee-balances.vue'),
        },
        {
          meta: {
            title: 'Bobtail Reports',
          },
          name: 'dashboard-employee-reports',
          path: 'employee-reports',
          props: true,
          component: () => import(/* webpackChunkName: "employee-reports" */'./views/dashboard/dashboard-employee-reports.vue'),
        },
        {
          meta: {
            title: 'Bobtail Collections',
          },
          name: 'dashboard-employee-collections',
          path: 'employee-collections',
          props: true,
          component: () => import(/* webpackChunkName: "employee-collections" */'./views/dashboard/dashboard-employee-collections.vue'),
        },
        {
          meta: {
            title: 'Bobtail Transfers',
          },
          name: 'dashboard-employee-transfers',
          path: 'employee-transfers',
          props: true,
          component: () => import(/* webpackChunkName: "employee-transfers" */'./views/dashboard/dashboard-employee-transfers.vue'),
        },
        {
          meta: {
            title: 'Bobtail Audit Log',
          },
          name: 'dashboard-employee-audit-log',
          path: 'employee-audit-log',
          props: true,
          component: () => import(/* webpackChunkName: "employee-audit-log" */'./views/dashboard/dashboard-employee-audit-log.vue'),
        },
        {
          meta: {
            title: 'Bobtail Quickbooks',
          },
          name: 'dashboard-employee-quickbooks',
          path: 'employee-quickbooks',
          props: true,
          component: () => import(/* webpackChunkName: "employee-quickbooks" */'./views/dashboard/dashboard-employee-quickbooks.vue'),
        },
        {
          meta: {
            title: 'Bobtail Volume',
          },
          name: 'dashboard-employee-volume',
          path: 'employee-volume',
          props: true,
          component: () => import(/* webpackChunkName: "employee-volume" */'./views/dashboard/dashboard-employee-volume.vue'),
        }
      ]
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: '',
      },
      name: 'debtor',
      path: '/debtor/:id',
      props: (route) => ({
        ...route.params,
        userIsMaster: userType.isMaster(),
      }),
      redirect: { name: 'debtor-profile' },
      component: () => import(/* webpackChunkName: "debtor" */ './views/debtor/profile/debtor.vue'),
      beforeEnter: (to, from, next) => {
        // Do not allow visiting "debtor not found" user page
        Debtor.getUnknownDebtorId().then(id => {
          if (to.params.id === id) {
            console.log('Access to Debtor Not Found not allowed')
            next({ name: 'user-management' })
          } else {
            next()
          }
        })
      },
      children: [
        {
          meta: {
            title: 'Debtor Profile',
          },
          name: 'debtor-profile',
          path: 'profile',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "debtor-profile" */ './views/debtor/profile/debtor-profile.vue'),
        },
        {
          meta: {
            title: 'Processing Notes',
          },
          name: 'debtor-processing-notes',
          path: 'notes',
          props: (route) => ({
            ...route.params,
            pagePOV: 'debtor',
          }),
          component: () => import(/* webpackChunkName: "user-profile-contacts" */ './views/debtor/profile/debtor-processing-notes.vue'),
        },
        {
          meta: {
            title: 'Debtor Contacts',
          },
          name: 'debtor-contacts',
          path: 'contacts',
          props: (route) => ({
            ...route.params,
            pagePOV: 'debtor',
          }),
          component: () => import(/* webpackChunkName: "user-profile-contacts" */ './components/user-profile-contacts.vue'),
        },
        {
          meta: {
            title: 'Debtor Credit',
          },
          name: 'debtor-credit',
          path: 'credit',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "debtor-credit" */ './views/debtor/profile/debtor-credit.vue'),
        },
        {
          meta: {
            title: 'Debtor Assignments',
          },
          name: 'debtor-assignments',
          title: 'Debtor Profile',
          path: 'assignments',
          props: (route) => ({
            ...route.params,
            pagePOV: 'debtor',
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "user-profile-assignments" */ './components/user-profile-assignments.vue'),
        },
        {
          meta: {
            title: 'Debtor Data',
          },
          name: 'debtor-data',
          path: 'data',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          redirect: { name: 'debtor-data-general' },
          component: () => import(/* webpackChunkName: "debtor-data" */ './views/debtor/profile/data/debtor-data.vue'),
          children: [
            {
              meta: {
                title: 'Debtor General Data',
              },
              name: 'debtor-data-general',
              path: 'general',
              props: (route) => ({
                ...route.params
              }),
              component: () => import(/* webpackChunkName: "debtor-data-general" */ './views/debtor/profile/data/debtor-data-general.vue'),
            },
            {
              meta: {
                title: 'Debtor Dilution Data',
              },
              name: 'debtor-data-dilution',
              path: 'dilution',
              props: (route) => ({
                ...route.params
              }),
              component: () => import(/* webpackChunkName: "debtor-data-dilution" */ './views/debtor/profile/data/debtor-data-dilution.vue'),
            },
          ],
        },
        {
          meta: {
            title: 'Debtor Manage',
          },
          name: 'debtor-manage',
          path: 'manage',
          props: (route) => ({
            ...route.params,
            pagePOV: 'debtor',
            userIsClient: userType.isClient(),
            userIsEmployee: userType.isEmployee(),
          }),
          component: () => import(/* webpackChunkName: "debtor-manage" */ './views/debtor/profile/debtor-manage.vue'),
        },
      ],
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: '',
      },
      name: 'invoices',
      path: '/invoices',
      redirect: { name: 'pending' },
      component: () => import('./views/invoices/invoices.vue'),
      children: [
        {
          meta: {
            title: 'Approved Invoices',
          },
          name: 'approved',
          path: 'approved',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient()
          }),
          component: () => import(/* webpackChunkName: "approved" */ './views/invoices/invoices-approved.vue'),
        },
        {
          meta: {
            title: 'Declined Invoices',
          },
          name: 'declined',
          path: 'declined',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient()
          }),
          component: () => import(/* webpackChunkName: "declined" */ './views/invoices/invoices-declined.vue'),
        },
        {
          meta: {
            title: 'Paid Invoices',
          },
          name: 'paid',
          path: 'paid',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient()
          }),
          component: () => import(/* webpackChunkName: "paid" */ './views/invoices/invoices-paid.vue'),
        },
        {
          meta: {
            title: 'Pending Invoices',
          },
          name: 'pending',
          path: 'pending',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient()
          }),
          component: () => import(/* webpackChunkName: "pending" */ './views/invoices/invoices-pending.vue'),
        },
        {
          meta: {
            title: 'Universal Search',
          },
          name: 'search-results',
          path: 'search-results',
          props: (route) => ({
            ...route.params,
            userIsClient: userType.isClient()
          }),
          component: () => import(/* webpackChunkName: "search-results" */ './views/invoices/invoice-search-results.vue'),
        },
      ]
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: 'Invoice Details',
      },
      name: 'invoice-details',
      path: '/invoice-details/:id',
      props: (route) => ({
        ...route.params,
        userIsClient: userType.isClient()
      }),
      component: () => import(/* webpackChunkName: "invoice-details" */ './views/invoices/invoice-details/invoice-details.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: false,
        mobileFriendly: true,
        title: 'Login',
      },
      name: 'login',
      path: '/login',
      props: true,
      component: () => import(/* webpackChunkName: "log-in" */ './views/auth/login.vue'),
      beforeEnter: async (to, from, next) => {
        // Since we're only checking for maintenance mode on auth required route changes
        // We need to check here, which is one of the only maintenanceFriendly guest routes
        // This will also prevent users from being logged out during maintenance by intercepting
        // the actual route to /login
        const maintenanceOn = (await maintenanceMode()).maintenance_mode
        if (maintenanceOn) next({ name: 'maintenance' })
        else next()
      },
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: false,
        mobileFriendly: true,
        title: '',
      },
      name: 'log-out',
      path: '/logout',
      component: () => import(/* webpackChunkName: "log-out" */ './views/auth/log-out.vue'),
      beforeEnter: (to, from, next) => {
        globalSignOut()
        if (process.env.VUE_APP_MARKETING_DEPLOY) {
          // route to root of marketing site
          window.location = '/'
        } else {
          next({ name: 'login' })
        }
      }
    },
    {
      meta: {
        guest: true, // Must be true so non-logged in users can be routed here
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Maintenance',
      },
      name: 'maintenance',
      path: '/maintenance',
      props: true,
      component: () => import(/* webpackChunkName: 'maintenance' */ './views/maintenance.vue'),
      beforeEnter: async (to, from, next) => {
        // Since we're only checking for maintenance mode on auth required route changes
        // We need to check here, which is one of the only maintenanceFriendly guest routes
        // This will also auto-redirect users to /invoices/pending if maintenance isn't on
        // because there's no reason someone needs to view this page outside of maintenance
        const maintenanceOn = (await maintenanceMode()).maintenance_mode
        if (maintenanceOn) next()
        else next({ name: 'pending' })
      },
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: false,
        mobileFriendly: true,
        title: 'Password Reset',
      },
      name: 'password-reset',
      path: '/password-reset',
      props: true,
      component: () => import(/* webpackChunkName: 'password-reset' */ './views/auth/password-reset.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Permission Denied',
      },
      name: 'permission-denied',
      path: '/permission-denied',
      props: true,
      component: () => import(/* webpackChunkName: 'permission-denied' */ './views/permission-denied.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Privacy',
      },
      name: 'privacy',
      path: '/privacy',
      props: true,
      component: () => import(/* webpackChunkName: 'privacy' */ './views/legal/privacy.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Release Notes',
      },
      name: 'release-notes',
      path: '/internal/release-notes',
      component: () => import(/* webpackChunkName: "release-notes" */ './views/release-notes.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: true,
        title: 'Sandbox',
      },
      name: 'sandbox',
      path: '/internal/test/sandbox',
      props: true,
      component: () => import(/* webpackChunkName: "sandbox" */ './views/test/sandbox.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: false,
        mobileFriendly: true,
        title: 'Set Password',
      },
      name: 'set-password',
      path: '/set-password',
      component: () => import(/* webpackChunkName: "set-password" */ './views/auth/set-password.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: true,
        mobileFriendly: false,
        title: 'Styleguide',
      },
      name: 'styleguide',
      path: '/internal/styleguide',
      component: () => import(/* webpackChunkName: "styleguide" */ './views/styleguide.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: true,
        requiresAuth: true,
        requiresClient: true,
        title: 'Submit Invoice',
      },
      name: 'submit-invoice',
      path: '/submit-invoice',
      props: (route) => ({
        ...route.params,
        userIsClient: userType.isClient()
      }),
      component: () => import(/* webpackChunkName: "submit-invoice" */ './views/invoices/submit-invoice.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: 'User Invoice',
      },
      name: 'user-invite',
      path: '/user-invite',
      component: () => import(/* webpackChunkName: "user-invite" */ './views/auth/user-invite.vue'),
    },
    {
      meta: {
        maintenanceFriendly: false,
        mobileFriendly: false,
        requiresAuth: true,
        requiresEmployee: true,
        title: 'User Management',
      },
      name: 'user-management',
      path: '/user-management',
      props: true,
      component: () => import(/* webpackChunkName: "user-management" */ './views/user-management.vue'),
    },
    {
      meta: {
        guest: true,
        maintenanceFriendly: false,
        mobileFriendly: true,
        title: 'Page Not Found',
      },
      name: 'page-not-found',
      path: '*',
      props: true,
      component: () => import(/* webpackChunkName: "404-page" */ './views/page-not-found.vue'),
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  // Avoids recursion when user is sent to /login for auth reasons
  if (to.matched.some(record => record.meta.guest)) {
    next()
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = JSON.parse(localStorage.getItem('user'))
    const token = await getCurrentJWTToken()
    // Before every route, check existence of active token or session then user data:
    // Check for no token, expired token, inactive session
    if (!token) {
      CLog(
        '%c Router.beforeEach: Active session not found or token expired',
        'color:red;font-size: 22px;'
      )
      next({ name: 'login', params: { previous: window.location.href } })
      return
    }

    // Check for user data
    if (!user) {
      next({ name: 'login', params: { previous: window.location.href } })
      return
    }

    // Check for maintenance mode
    const isMaintenanceMode = (await maintenanceMode()).maintenance_mode
    if (isMaintenanceMode) {
      // Requires maintenanceFriendly === true
      if (to.matched.some(record => record.meta.maintenanceFriendly)) {
        // Allow masters to go to maintenanceFriendly pages
        if (user.employee && user.employee.is_master) {
          next()
          return
        }
        // All other users go to maintenance
        next({ name: 'maintenance' })
        return
      }
      // If not a maintenanceFriendly page, go to maintenance
      next({ name: 'maintenance' })
      return
    }

    // Check for a non-mobile route when mobile viewing
    if (isMobile()) {
      // to route is not included in user's allowed mobile routes
      if (!to.matched.some(record => record.meta.mobileFriendly)) {
        next({
          name: 'permission-denied',
          params: {
            reason: 'This page is not yet converted for mobile viewing. We apologize for the inconvenience. If you would like to be able to access this page on mobile please reach out to Bobtail!',
            title: 'Inaccessible on mobile'
          }
        })
        // next(from)
      }
    }

    // Requires Client+
    if (to.matched.some(record => record.meta.requiresClient)) {
      if (userType.isClient() || userType.isEmployee()) {
        next()
        return
      }
      next({ name: 'permission-denied', params: { reason: 'You do not have permission to view this page' } })
      return
    }

    // Requires Employee+
    if (to.matched.some(record => record.meta.requiresEmployee)) {
      if (userType.isEmployee()) {
        next()
        return
      }
      next({ name: 'permission-denied', params: { reason: 'You do not have permission to view this page' } })
      return
    }

    // Requires Master
    if (to.matched.some(record => record.meta.requiresMaster)) {
      if (userType.isEmployee() && userType.isMaster()) {
        next()
        return
      }
      next({ name: 'permission-denied', params: { reason: 'You do not have permission to view this page' } })
    }
  }
})

// If user gets a "Loading chunk X failed", reload once
router.onError((error) => {
  const pattern = /^Loading chunk [0-9]+ failed/.test(error.message)
  if (pattern && !window.location.hash.includes('#retry')) {
    window.location.hash = '#retry'
    window.location.reload(true)
  }
})

export default router
