<template>
  <div class="BaseModal">
    <div class="BaseModal__bg" />
    <div
      class="BaseModal__content column"
      ref="BaseModal__content"
    >
      <label
        :class="`fs-20 fw-med mb-24 ${labelClass}`"
        data-cy="base-modal-label-slot"
      >
        <slot name="label" />
      </label>
      <slot name="input" />
      <div :class="`row mt-24 ${actionsClass}`">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    actionsClass: {
      type: String,
      required: false,
      default: null,
    },
    labelClass: {
      type: String,
      required: false,
      default: null,
    },
  },

  // Easier to access <body> via a bit of JS than making some confusing CSS hack
  mounted () {
    this.setTop()
    window.addEventListener('resize', this.setTop)
    document.body.style.overflow = 'hidden'
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setTop)
    document.body.style.overflow = 'visible'
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    setTop () {
      // If mobile, we want to give it a little more room for dynamic content in the modal, so less top
      // https://bobtail.atlassian.net/browse/SM-266
      const defaultTop = (window.innerHeight - this.$refs.BaseModal__content.offsetHeight) / 2
      const top = !this.mobile.isMobile
        ? defaultTop
        : defaultTop - (defaultTop / 2)
      this.$refs.BaseModal__content.style.top = `${top}px`
    },
  },
}
</script>

<style lang="sass" scoped>
.BaseModal
  height: 100%
  left: 0
  position: fixed
  top: 0
  width: 100%
  // Highest z-index app-wide
  z-index: 5

  &__bg
    background-color: $text-light
    height: 100%
    opacity: 0.5
    position: absolute
    width: 100%

  &__content
    background-color: $white
    box-shadow: $modal-shadow
    margin: 0 auto
    max-height: 75vh
    max-width: rem(540px)
    padding: rem(31px) rem(39px)
    position: relative
    width: 100%
    z-index: 4

  @media #{$mobile-large-and-down}
    &__content
      margin-top: rem(80px)
      max-width: rem(300px)
      padding: 1rem
      width: auto
</style>