import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Employee = new Entity('employees')

Employee.getEmployees = async (params, searchLimit = null) => {
  const defaultSearchLimit = searchLimit || 25
  return (await bobtailAPI()).get(Employee.baseUrl, {
    params: {
      limit: defaultSearchLimit,
      ...params,
    },
  })
}

Employee.resetPassword = async (params) => {
  return (await bobtailAPI()).post(`${Employee.baseUrl}/send-invitation/${params.id}`, {
    params
  })
}

export default Employee