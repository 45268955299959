import bobtailAPI from '../axiosConfig'

export default class Cron {
  static baseUrl = '/api/cron'

  static async reRunNachaJob (params) {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/resend-nacha/${params.id}`, params)
  }

  static async runAllWires (params) {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/admin-wire`, params)
  }

  static async runClientWire (params) {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/wire/${params.id}`, params)
  }

  static async runDailyReport (params) {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/dailyreport`, params)
  }

  static async runNachaJob () {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/admin-nacha`)
  }

  static async runRemainingQuickbooksJobs (params) {
    return (await bobtailAPI()).post(`${Cron.baseUrl}/quickbooks-jobs-manual`, {
      params
    })
  }
}