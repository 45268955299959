// Helpers
import {
  DELETE_INVOICE,
  DELETE_UNIVERSAL_SEARCH_INVOICE,
  INSERT_INVOICE,
  REPLACE_EXISTING_INVOICE,
  REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE,
  STORE_INVOICES,
  STORE_UNIVERSAL_SEARCH_RESULTS
} from './mutation-types'
import { CLog } from '../utils/consoleMixin'
import { transformRawInvoice } from '../utils/helpers'

const findInvoiceFromId = (invoices, id) => {
  return invoices.find(invoice => invoice.id === id)
}

const mutations = {
  // If there is a change here, should be a change in DELETE_UNIVERSAL_SEARCH_INVOICE
  [DELETE_INVOICE]: (state, payload) => {
    const invoice = findInvoiceFromId(state.invoices.invoices, payload)
    // invoices.invoices may be empty, and that's alright; we can skip the rest
    if (!invoice) {
      return
    }
    const invoiceIndex = state.invoices.invoices.indexOf(invoice)
    state.invoices.invoices.splice(invoiceIndex, 1)
    state.invoices.total -= 1
    state.invoices.sum -= Number.parseFloat((invoice.amounts.total * 100).toFixed(2))
  },

  // If there is a change here, should be a change in DELETE_INVOICE
  [DELETE_UNIVERSAL_SEARCH_INVOICE]: (state, payload) => {
    const invoice = findInvoiceFromId(state.universalSearchResults.invoices, payload)
    // invoices.invoices may be empty, and that's alright; we can skip the rest
    if (!invoice) {
      return
    }
    const invoiceIndex = state.universalSearchResults.invoices.indexOf(invoice)
    state.universalSearchResults.invoices.splice(invoiceIndex, 1)
    state.universalSearchResults.total -= 1
    state.universalSearchResults.sum -= Number.parseFloat((invoice.amounts.total * 100).toFixed(2))
  },

  [INSERT_INVOICE]: (state, invoice) => {
    if (!invoice) return

    /*
     * New invoices created by employees get added to the END of the page's invoices
     * UNLESS the page already has 25 invoices (represented by # of invoices in store)
     *
     * New invoices created by clients get added to the top of the page's invoices
     * REGARDLESS of how many invoices are on the page (should not affect anything at all)
     */
    const user = JSON.parse(localStorage.getItem('user'))

    // If it's a client user, add the invoice to the front of the line, regardless
    if (user.client) {
      state.invoices.invoices.unshift(transformRawInvoice(invoice))
    }
    if (user.employee && state.invoices.invoices.length < 25) {
      state.invoices.invoices.push(transformRawInvoice(invoice))
    }
    state.invoices.total = state.invoices.total ? state.invoices.total + 1 : 1
    state.invoices.sum = state.invoices.sum
      ? state.invoices.sum + Number.parseFloat(invoice.total_amount.toFixed(2))
      : Number.parseFloat(invoice.total_amount.toFixed(2))
  },

  // If there is a change here, should be a change in REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE
  [REPLACE_EXISTING_INVOICE]: (state, payload) => {
    if (!state.invoices.invoices.length) {
      CLog('Currently, there are no invoices stored in Vuex to look for a match to replace.')
      return
    }
    const invoice = findInvoiceFromId(state.invoices.invoices, payload.id)
    if (!invoice) {
      CLog('No matching invoices found.')
      return
    }
    const invoiceIndex = state.invoices.invoices.indexOf(invoice)
    state.invoices.invoices.splice(invoiceIndex, 1, transformRawInvoice(payload))
  },

  // If there is a change here, should be a change in REPLACE_EXISTING_INVOICE
  [REPLACE_EXISTING_UNIVERSAL_SEARCH_INVOICE]: (state, payload) => {
    if (!state.universalSearchResults.invoices.length) {
      CLog('Currently, there are no universal search invoices stored in Vuex to look for a match to replace.')
      return
    }
    const invoice = findInvoiceFromId(state.universalSearchResults.invoices, payload.id)
    if (!invoice) {
      CLog('No matching universal search invoices found.')
      return
    }
    const invoiceIndex = state.universalSearchResults.invoices.indexOf(invoice)
    state.universalSearchResults.invoices.splice(invoiceIndex, 1, transformRawInvoice(payload))
  },

  [STORE_INVOICES]: (state, payload) => {
    // Format payload into frontend required model
    const count = payload.nbHits ? payload.nbHits : payload.count
    const searchStats = payload.facets_stats || null
    const invoices = payload.hits || payload.rows || payload.invoices
    const transformedInvoices = []

    invoices.forEach(invoice => {
      transformedInvoices.push(transformRawInvoice(invoice))
    })
    state.invoices.invoices = transformedInvoices

    if (payload.facets_stats) {
      state.invoices.sum = payload.facets_stats.total_amount.sum
    } else if (invoices.length) {
      state.invoices.sum = invoices.reduce((acc, cur) => acc + cur.total_amount, 0)
    } else {
      state.invoices.sum = false
    }
    state.invoices.stats = searchStats
    state.invoices.total = count
  },

  [STORE_UNIVERSAL_SEARCH_RESULTS]: (state, payload) => {
    // Format payload into frontend required model
    const count = payload.nbHits ? payload.nbHits : payload.count
    const transformedInvoices = []
    const invoices = payload.hits || payload.rows || payload.invoices

    invoices.forEach(invoice => {
      transformedInvoices.push(transformRawInvoice(invoice))
    })
    state.universalSearchResults.invoices = transformedInvoices

    if (invoices.length) {
      state.universalSearchResults.sum = invoices
        .reduce((acc, cur) => acc + (cur.total_amount / 100), 0)
    } else {
      state.universalSearchResults.sum = false
    }
    state.universalSearchResults.total = count
  }
}

export default mutations
