import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { ExtraErrorData, Vue as VueIntegration } from '@sentry/integrations'
import Vue from 'vue'
import { Integrations as ApmIntegrations } from '@sentry/apm'
import { CWarn, CError } from './consoleMixin'
import { getCurrentJWTToken } from './cognito'

const disabledEnvironments = ['demo', 'development', 'productionlocal', 'testing', 'devcloud']
const environment = process.env.VUE_APP_ENV_NAME

export const captureSentryException = (exception) => {
  if (!disabledEnvironments.includes(environment)) {
    Sentry.captureException(exception)
  } else {
    CWarn('SENTRY IS NOT ENABLED FOR ENV:', environment)
  }
}

/*
 for tracking a generic event
 label is the event name
 data is some data to attach to it
 can use this for passing state along with an exception
 level can be fatal, error, warning, info, and debug
*/
export const captureSentryEvent = (label, state, level = 'error') => {
  if (!disabledEnvironments.includes(environment)) {
    Sentry.configureScope(scope => {
      scope.setExtra('state', state)
      scope.setLevel(level)
      Sentry.captureEvent({
        message: label
      })
    })
  } else {
    CWarn('SENTRY IS NOT ENABLED FOR ENV:', environment)
  }
}

// for capturing a generic message
// message is just a string, level is severity
export const captureSentryMessage = (message, level = 'info') => {
  if (!disabledEnvironments.includes(environment)) {
    Sentry.captureMessage(message, level)
  } else {
    CWarn('SENTRY IS NOT ENABLED FOR ENV:', environment)
  }
}

export default {
  methods: {
    async initializeSentry () {
      const version = process.env.VUE_APP_GIT_RELEASE_VERSION
      if (!disabledEnvironments.includes(environment)) {
        let metadata = {}
        try {
          metadata = (await axios.get('https://json.geoiplookup.io/')).data
        } catch (err) {
          CError('Error loading metadata:', err)
        }
        const user = JSON.parse(localStorage.getItem('user')) || {}
        // This will allow us to search for issues based on type of user
        Sentry.setTag('isClient', !!user.client)
        Sentry.setTag('isEmployee', !!user.employee)
        Sentry.setTag('isMaster', !!user.employee && !!user.employee.is_master)
        Sentry.setTag('metadata', metadata)
        Sentry.init({
          async beforeSend(event) {
            // You can only attach properties that Sentry is expecting. If you need something special
            // and want to track it, add it as a tag above
            event.user = user
            event.user.cognito = await getCurrentJWTToken()
            return event
          },
          dsn: process.env.VUE_APP_SENTRY_CLIENT_DSN,
          environment,
          // ExtraErrorData allows deeper level objects in additional data state
          integrations: [
            new ExtraErrorData({ depth: 7 }),
            new VueIntegration({ Vue, attachProps: true, logErrors: true }),
            new ApmIntegrations.Tracing({
              tracingOrigins: [process.env.VUE_APP_BASE_URL]
            })
          ],
          normalizeDepth: 8, // ExtraErrorData depth + 1
          release: version,
          tracesSampleRate: 0.1 // capture 10% of transactions - to prevent killing quota fast
        })
      } else {
        CWarn('SENTRY IS NOT ENABLED FOR ENV:', environment)
      }

      // sample event
      // captureSentryEvent('null value in search', {
      //   data: {
      //     value: 'test'
      //   },
      //   user_id: 1,
      //   client_id: 65
      // }, 'debug')

      // sample error
      // captureSentryException(new Error('an error occurred!'))

      // sample message
      // captureSentryMessage('user downloaded report', 'info)
    },

    captureSentryException,
    captureSentryEvent,
    captureSentryMessage
  }
}
