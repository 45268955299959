var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "BaseButton",
      attrs: { "aria-disabled": _vm.disabled, disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "BaseButton__inner fs-14 no-wrap",
          attrs: { tabindex: "-1" }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }