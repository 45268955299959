<template>
  <div
    v-click-outside="clickedOutside"
    class="BaseUserSearch column"
    data-cy="base-client-search"
  >
    <label
      v-if="label"
      class="BaseUserSearch__label fc-light fs-12 uppercase no-select"
      :for="labelFor"
    >
      <slot name="label" />
    </label>
    <input
      v-model.trim="input"
      @input="input = $event.target.value.trim()"
      @keydown.40.prevent="moveToResults"
      autocomplete="off"
      :class="[
        'BaseUserSearch__input bg-white fs-14',
        { 'BaseUserSearch__input--error': !valid && !client },
        { 'BaseUserSearch__input--w-dropdown': dropdown }
      ]"
      data-cy="base-client-search-input"
      :disabled="disabled"
      :id="labelFor"
      :placeholder="placeholder"
      ref="BaseUserSearch__input"
    >
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <!-- Disabling until we need this for client users <i
        v-show="searching"
        class="fa fa-processing fa-spin fa--none"
      /> -->
    </transition>
    <transition name="Transition__fade">
      <label
        v-if="!valid && !client"
        class="fc-red fs-12"
      >
        {{ instructions }}
      </label>
    </transition>
    <div class="BaseUserSearch__list-wrapper">
      <ul
        v-if="results.length && dropdown"
        @keydown.40.prevent="moveDownResults"
        @keydown.38.prevent="moveUpResults"
        class="BaseUserSearch__list bg-white"
        ref="BaseUserSearch__list"
      >
        <li
          v-for="(clientResult, index) in results"
          :key="index"
          @click="select(clientResult, $event)"
          @keypress.space.enter="select(clientResult, $event)"
          class="BaseUserSearch__list-item column"
          :data-cy="`base-client-search-result-${index}`"
          tabindex="0"
        >
          <span class="fs-14">{{ clientResult.shortened_name }}</span>
          <span
            v-if="clientResult.mc"
            class="fc-light fs-12"
          >
            {{ `MC ${clientResult.mc}` }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// Packages
import DOMPurify from 'dompurify'
// Helpers
import algoliaSearch from '../utils/algoliaSearch'
import { Client } from '../utils/api'

export default {
  name: 'BaseUserSearch',

  props: {
    clientIDFromQuery: {
      type: [Number, String],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    instructions: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelFor: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    resetAfterSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  async created () {
    if (!this.clientIDFromQuery) return
    this.getClient(this.clientIDFromQuery)
  },

  data () {
    return {
      client: null,
      dropdown: false,
      input: '',
      results: [],
      searching: false,
    }
  },

  watch: {
    async clientIDFromQuery (newValue, oldValue) {
      // If this.clientIDFromQuery doesn't exist on created,
      // oldValue will be null when freshly selecting a result
      if (oldValue === null) return
      // When going from a value to erasing the input's value, reset component data
      if (newValue === null) {
        this.resetData()
        return
      }
      // When the incoming this.clientIDFromQuery is different than the current
      if (oldValue.id !== newValue.id) {
        await this.getClient(newValue)
      }
    },

    input () {
      if (this.input === '') {
        this.client = null
        this.dropdown = false
        // Let parent components know there is NO selected
        // Do NOT report null if reseting after selected (base-select-search), because
        // this will trigger a double route to a client page with a null value for the ID
        if (!this.resetAfterSelected) this.$emit('selected', null)
        return
      }
      if (this.client && this.input === this.client.shortened_name) {
        this.dropdown = false
        return
      }
      this.search()
    },
  },

  methods: {
    clickedOutside () {
      this.dropdown = false
      this.searching = false
      this.results = []
      if (this.client) this.input = this.client.shortened_name
      else this.input = ''
      this.$emit('selected', this.client)
    },

    async getClient (id) {
      try {
        this.client = (await Client.get(id)).data
        this.input = this.client.shortened_name
        this.$emit('selected', this.client)
        if (this.resetAfterSelected) this.resetData()
      }
      catch (error) {
        this.captureSentryEvent(
          'Client Search "Get Client" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
      }
    },

    moveDownResults (e) {
      const nextSibling = e.target.nextSibling
      if (nextSibling === null) return
      nextSibling.focus()
    },

    moveToResults () {
      if (!this.results.length) return
      this.$refs.BaseUserSearch__list.childNodes[0].focus()
    },

    moveUpResults (e) {
      const previousSibling = e.target.previousSibling
      if (previousSibling === null) {
        this.$refs.BaseUserSearch__input.focus()
        return
      }
      previousSibling.focus()
    },

    resetData () {
      Object.assign(this.$data, this.$options.data())
    },

    async search () {
      this.dropdown = true
      this.searching = true
      try {
        const search = DOMPurify.sanitize(this.input)
        this.results = (await algoliaSearch.clientSearch(search)).data.hits
        this.searching = false
      }
      catch (error) {
        this.searching = false
        this.captureSentryEvent(
          'Client Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue finding matching clients' })
      }
    },

    select (result, event) {
      event.preventDefault()
      this.dropdown = false
      this.client = result
      this.input = result.shortened_name
      this.results = []
      this.$emit('selected', this.client)
      if (this.resetAfterSelected) this.resetData()
      this.$refs.BaseUserSearch__input.focus()
    },
  },
}
</script>

<style lang="sass">
@import '../styles/user-search.sass'
</style>
