/* eslint-disable max-len */
// This is copied to /marketing-website/utils/api/api-service
// If it needs updated for any reason, please update /marketing-website if applicable
// Packages
import moment from 'moment'
import validator from 'validator'
// Helpers
import { ApiService } from './api'

// Regex used in instructions and data.regex
// UPDATE SAME VARIABLES IN CYPRESS/CONSTANTS.JS
const legalNameRegex = '-.,\'\"_/#& '
const shortenedNameRegex = '-\'& '

// eslint-disable-next-line import/prefer-default-export
export const ValidationMixin = {
  data() {
    return {
      instructions: {
        ACCOUNT_MANAGER_INSTRUCTION: 'Letters only',
        ACCOUNT_MANAGER_ID_INSTRUCTION: 'Please select an account manager',
        ADDRESS_INSTRUCTION: 'Letters, numbers, spaces, and `-._/#` only',
        ADDRESS_2_INSTRUCTION: 'Letters, numbers, spaces, and `-._/#` only',
        ADJUSTMENT_INSTRUCTION: 'Must be a number equal to or greater than 0',
        ADVANCE_INSTRUCTION: 'Must be empty or a number',
        AUTHORITY_DATE_INSTRUCTION: 'Must be a date before today',
        BANK_NAME_INSTRUCTION: 'Letters, numbers, apostrophes, and spaces only',
        BANK_OWNER_NAME_INSTRUCTION: 'Letters and spaces only',
        BATCH_DATE_INSTRUCTION: 'Must not be in the future',
        BATCH_DATE_INSTRUCTION_LONG: 'Batch date must not be in the future',
        BUYOUT_DATE_INSTRUCTION: 'Buyout date must not be in the future',
        CITY_INSTRUCTION: 'Letters and spaces only',
        CHECK_NUMBER_INSTRUCTION: 'Must be a number greater than 0',
        CHECK_NUMBER_INSTRUCTION_LONG: 'Check number must be a number greater than 0',
        CLIENT_INSTRUCTION: 'You must select a client',
        COLD_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        CORPORATION_TYPE_INSTRUCTION: 'Must be \'llc\', \'corp\', or \'sole proprietor\'',
        CREDIT_RATING_INSTRUCTION: 'Must be A, B, C, D, or F',
        CREDIT_RATING_REASON_INSTRUCTION: 'Must not be empty',
        DATE_INSTRUCTION: 'Must be a date before today',
        DATE_OF_BIRTH_INSTRUCTION: 'Must be a date before today',
        DAYS_OUTSTANDING_FROM_INSTRUCTION: 'Must be a number equal to or greater than 0',
        DAYS_OUTSTANDING_TO_INSTRUCTION: 'Must be a number equal to or greater than 0',
        // DBAs are names, so they much allow the same thing as any name type property
        DBA_CLIENT_INSTRUCTION: `1 DBA only. Letters, numbers, spaces, and ${legalNameRegex} only`,
        // Debtor DBAs can have more than one, so a comma is needed
        DBA_DEBTOR_INSTRUCTION: `Letters, numbers, spaces, and ${shortenedNameRegex} only`,
        DEBTOR_INSTRUCTION: 'You must select a debtor',
        DETENTION_INSTRUCTION: 'Must be empty or a number equal to or greater than 0',
        DRIVER_NAME_INSTRUCTION: 'Letters and spaces only',
        DOLLAR_INSTRUCTION: 'Must be a number equal to or greater than 0',
        DOT_INSTRUCTION: 'Letters and numbers only',
        DOT_UNIQUE_INSTRUCTION: 'Dot must be unique and contain letters and numbers only',
        DRYVAN_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        EIN_INSTRUCTION: 'Letters and numbers only',
        EIN_UNIQUE_INSTRUCTION: 'EIN must be unique and letters and numbers only',
        EMAIL_INSTRUCTION: 'Must be an email (`@`, domain name, ending required)',
        EMAIL_UNIQUE_INSTRUCTION: 'Must be a unique and valid email',
        EQUITY_INSTRUCTION: 'Must be a number (0 ≤ X ≥ 100)',
        END_DATE_INSTRUCTION: 'Date must be after the start date',
        EXTENSION_INSTRUCTION: 'Must be a number',
        EXTERNAL_DAYS_INSTRUCTION: 'Must be a number equal to or greater than 0', // 60, 90, total,
        EXTERNAL_INVOICES_INSTRUCTION: 'Must be a number equal to or greater than 0', // 60, 90, total
        FACTOR_RATE_PERCENTAGE_INSTRUCTION: 'Must be a number (0 ≤ X ≤ 5)',
        FAX_INSTRUCTION: 'Must be a 10 or 11-digit number ((), -, ext. ### allowed)',
        FLATBED_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        HIGH_CREDIT_INSTRUCTION: 'Must be a number equal to or greater than 0',
        ID_INSTRUCTION: 'Must be a positive number',
        INSURANCE_COMPANY_NAME_INSTRUCTION: `Letters, spaces, and ${shortenedNameRegex} only`,
        INSURANCE_EFFECTIVE_DATE_INSTRUCTION: 'Must be a date before today',
        INSURANCE_PHONE_INSTRUCTION: 'Must be a 10 or 11-digit number ((), -, ext. ### allowed)',
        INSURANCE_PHONE_CONNECTED_INSTRUCTION: 'Must be a connected 10-digit number',
        INSURANCE_POLICY_NUMBER_INSTRUCTION: 'Must be a positive number',
        INSURANCE_RENEWAL_DATE_INSTRUCTION: 'Must be a date after today',
        INVOICE_DELIVERY_EMAILS_INSTRUCTION:
          'Each email must have a @, domain name, and ending and be separated by a comma',
        LANGUAGES_INSTRUCTION: 'Must be \'english\', \'punjabi\', or \'spanish\'',
        LEAD_ATTRIBUTION_INSTRUCTION: 'Must be \'referral\', \'web\', \'email\', or \'cold calling\'',
        LEGAL_NAME_INSTRUCTION: `Letters, numbers, spaces, and ${legalNameRegex} only`,
        LOAD_NUMBER_INSTRUCTION: 'Letters and numbers only. No spaces or -\'s.',
        LUMPER_INSTRUCTION: 'Must be empty or a number equal to or greater than 0',
        MC_INSTRUCTION: 'Letters and numbers only',
        MC_UNIQUE_INSTRUCTION: 'MC must be unique and contain letters and numbers only',
        MICRO_DEPOSIT_INSTRUCTION: 'Must be a decimal between 0 and 1',
        MONTHLY_PAYMENT_PER_TRUCK_INSTRUCTION: 'Must be a number greater than 0',
        NOA_EMAILS_INSTRUCTION: 'Each email must have a @, domain name, and ending and be separated by a comma',
        ONLINE_SUBMIT_URL_INSTRUCTION: 'Must be a valid URL',
        OTHER_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        PASSWORD_INSTRUCTION: 'Must be 8+ characters long, contain at least 1 uppercase letter, number and special character',
        PASSWORD_VERIFICATION_INSTRUCTION: 'Must match password',
        PAYMENT_AMOUNT_INSTRUCTION: 'Must be a number greater than 0',
        PAYMENT_AMOUNT_INSTRUCTION_LONG: 'Payment amount must be a number greater than 0',
        PAY_STATUS_EMAILS_INSTRUCTION: 'Each email must have a @, domain name, and ending',
        PHONE_INSTRUCTION: 'Must be a 10 or 11-digit number ((), -, ext. ### allowed)',
        PHONE_CONNECTED_INSTRUCTION: 'Must be a connected number',
        PRIMARY_RATE_INSTRUCTION: 'Must be a number greater than 0',
        QUERY_INSTRUCTION: 'Letters and numbers only',
        RATING_REASON_INSTRUCTION: 'N/A',
        REFRIGERATED_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        ROLE_INSTRUCTION: 'Letters, numbers, and spaces only',
        ROUTING_NUMBER_INSTRUCTION: 'Must be nine numbers long.',
        SALES_REP_ID_INSTRUCTION: 'Please select a sales manager',
        SHORTENED_NAME_INSTRUCTION: `Letters, numbers, spaces, and ${shortenedNameRegex} only`,
        START_DATE_INSTRUCTION: 'Date must not be in the future',
        STATE_INSTRUCTION: 'Letters and spaces only',
        STEPDECK_TRUCKS_INSTRUCTION: 'Must be a number equal to or greater than 0',
        TRANSFER_DATE_INSTRUCTION: 'Date must not be in the future',
        UPLOAD_DEBTORS_DOWNLOAD_START_DATE_INSTRUCTION: 'Date must be before end date',
        YEAR_STARTED_INSTRUCTION: 'Must be a number greater than 0 and less than or equal to today\'s year',
        ZIP_INSTRUCTION: 'Must be a 5-digit number or longer',
      },
      regex: {
        address: '-._/# ',
        checkNumber: '-',
        clientDBA: legalNameRegex,
        debtorDBA: shortenedNameRegex,
        legalName: legalNameRegex,
        shortenedName: shortenedNameRegex,
      },
    }
  },

  methods: {
    // NOTE NOTE NOTE
    // If you an input with JUST the blacklisted characters, it will return not valid, becuase
    // you're only passing an empty string to the validator function after blacklisting

    async checkPhoneNumber (phone, prefix = null) {
      if (!phone) return false
      try {
        return (
          await ApiService.checkPhoneConnected({ phone, prefix })
        ).data
      }
      catch (error) {
        this.captureSentryEvent(
          'validationMixin: checkPhoneNumber',
          {
            config: error.config,
            details: error,
            phone,
            prefix,
            response: error.response,
          }
        )
        this.CError(error)
        return false
      }
    },

    isValidAccountManagerId (id) {
      if (!id) return false
      return validator.isNumeric(id)
    },

    isValidAddress (address) {
      if (!address) return false
      const blacklisted = validator.blacklist(address, this.regex.address)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidAdjustment (adjustment) {
      if (!adjustment) return false
      const blacklisted = validator.blacklist(adjustment, ',')
      return validator.isFloat(blacklisted, { min: 0.00 })
    },

    isValidAdvance (advance) {
      if (!advance) return false
      const blacklisted = validator.blacklist(advance, ',')
      return validator.isFloat(blacklisted, { min: -21000000, max: 21000000 })
    },

    isValidAgencyName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, this.regex.shortenedName)
      return validator.isAlpha(blacklisted)
    },

    isValidAuthorityDate (date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidBankName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, '\' ')
      return validator.isAlphanumeric(blacklisted)
    },

    isValidBankOwner (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, ' ')
      return validator.isAlpha(blacklisted)
    },

    isValidBatchDate (date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidBuyoutDate (date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidBusinessName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, this.regex.legalName)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidCheckNumber (checkNumber) {
      if (!checkNumber) return false
      const blacklisted = validator.blacklist(checkNumber, this.regex.checkNumber)
      return validator.isInt(blacklisted, { gt: 0 })
    },

    isValidCity (city) {
      if (!city) return false
      const blacklisted = validator.blacklist(city, ' ')
      return validator.isAlpha(blacklisted)
    },

    isValidContactName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, this.regex.shortenedName)
      return validator.isAlpha(blacklisted)
    },

    isValidContactRole (role) {
      if (!role) return false
      const blacklisted = validator.blacklist(role, this.regex.shortenedName)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidCorpType (type) {
      if (!type) return false
      const corpTypes = ['llc', 'corp', 'sole proprietor', 'partnership']
      return corpTypes.includes(type)
    },

    isValidCreditRating (rating) {
      if (!rating) return false
      const ratings = ['A', 'B', 'C', 'D', 'F']
      return ratings.includes(rating)
    },

    isValidCreditRatingReason (reason) {
      if (!reason) return false
      return reason.length
    },

    isValidDateOfBirth(date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidDateRegistered (date) {
      if (!date) return false
      return moment(date).isValid()
    },

    isValidDaysOutstanding (days) {
      if (!days) return false
      return validator.isInt(days, { min: 0 })
    },

    isValidDBAClient (dba) {
      if (!dba) return true
      const blacklisted = validator.blacklist(dba.toLowerCase(), this.regex.clientDBA)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidDBADebtor (dbas) {
      if (!dbas) return true
      const blacklisted = validator.blacklist(dbas.toLowerCase(), this.regex.debtorDBA)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidDetention (detention) {
      if (!detention) return false
      const blacklisted = validator.blacklist(detention, ',')
      return validator.isFloat(blacklisted, { min: 0, max: 21000000 })
    },

    isValidDriverName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, ' ')
      return validator.isAlpha(blacklisted)
    },

    isValidDOT (dot) {
      if (!dot) return false
      return validator.isAlphanumeric(dot)
    },

    isValidEffectiveDate (date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidEIN (ein) {
      if (!ein) return false
      return validator.isAlphanumeric(ein)
    },

    isValidEmail (email) {
      if (!email) return false
      return validator.isEmail(email)
    },

    isValidEmails (emails) {
      if (!emails) return false
      let valid = true

      // Regardless of whether `emails` is a string or array, `.split(...)` works as intended
      emails.split(',').forEach(email => {
        if (!this.isValidEmail(email.trim())) {
          valid = false
        }
      })

      return valid
    },

    isValidEndDate (startDate, endDate) {
      if (!startDate || !endDate) return false
      // As of 20200814, employees want to create reports for singular days
      return moment(endDate).isSameOrAfter(startDate)
    },

    isValidEquity (equity) {
      if (!equity) return false
      return validator.isFloat(equity, { min: 0.00, max: 100.00 })
    },

    isValidExtension (extension) {
      if (!extension) return false
      return validator.isNumeric(extension)
    },

    isValidExternal (external) {
      if (!external) return false
      return validator.isInt(external, { min: 0 })
    },

    isValidFactorRate (rate) {
      if (!rate) return false
      return validator.isFloat(rate, { min: 0.00, max: 5.00 })
    },

    isValidHighCredit (credit) {
      if (!credit) return false
      return validator.isInt(credit, { min: 0 })
    },

    isValidID (id) {
      if (!id) return false
      return validator.isInt(id, { min: 0 })
    },

    isValidInsuranceCompanyName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, this.regex.shortenedName)
      return validator.isAlpha(blacklisted)
    },

    isValidInsurancePolicyNumber (number) {
      if (!number) return false
      return validator.isInt(number, { gt: 0 })
    },

    isValidInsuranceRenewalDate (date) {
      if (!date) return true
      return moment(date).isValid() && moment(date).isAfter(moment())
    },

    isValidLanguage (languages) {
      if (!languages) return false
      const options = ['english', 'punjabi', 'spanish']
      return languages.length && languages.every(lang => options.includes(lang))
    },

    isValidLeadAttribution (attribution) {
      if (!attribution) return false
      const attributions = ['referral', 'web', 'email', 'cold calling']
      return attributions.includes(attribution)
    },

    isValidLegalName (name) {
      if (!name) return false
      const blacklisted = validator.blacklist(name, this.regex.legalName)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidLoadNumber (loadNumber, allowDash = false) {
      if (!loadNumber) return false
      if (allowDash) loadNumber = loadNumber.replace(/-/g, '')
      return validator.isAlphanumeric(loadNumber)
    },

    isValidLumper (lumper) {
      if (!lumper) return false
      const blacklisted = validator.blacklist(lumper, ',')
      return validator.isFloat(blacklisted, { min: 0, max: 21000000 })
    },

    isValidMC (mc) {
      if (!mc) return false
      return validator.isAlphanumeric(mc)
    },

    isValidMicroDeposit(deposit) {
      if (!deposit) return false
      // We fake the $0. for the user, so they're just entering the cents amount
      return validator.isInt(deposit, { gt: 0, lt: 100 })
    },

    isValidMonthlyPerTruck (payment) {
      if (!payment) return false
      return validator.isFloat(payment, { min: 0 })
    },

    isValidPassword (pass) {
      if (!pass) return false
      const specialCharRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
      let uppercaseLetterExists = false

      // Check for an uppercase letter
      pass.split('').forEach(ch => {
        if (!isNaN(ch * 1)) return // Catches numbers
        if (specialCharRegex.test(ch)) return // Catches special characters
        if (ch === ch.toUpperCase()) uppercaseLetterExists = true
      })

      return (
        pass.length >= 8
        && pass.search(/([0-9.])/g) !== -1
        && specialCharRegex.test(pass)
        && uppercaseLetterExists
      )
    },

    isValidPayAmount (amount) {
      if (!amount) return false
      const blacklisted = validator.blacklist(amount, ',')
      return validator.isFloat(blacklisted, { min: 0 })
    },

    isValidPhone (phone) {
      if (!phone) return false
      // phone (format: "(area)XXX-XXXX xEXT")
      /*
       * Covers the following use cases:
       * 123 456 7890
       * 123.456.7890
       * 123-456-7890
       * (123) 456 7890
       * (123)4567890
       * (123)-456-7890
       * (123).456.7890
       * 123456.7890
       * (123) 456.7890
       * (123)456-7890
       * x123
       * x123
       * [number] [ext]
       * But it also allows 123.456.7890 [e/x/t]123
       */
      // const arr = ['123 456 7890', '123.456.7890', '123-456-7890', '(123) 456 7890', '(123)4567890', '(123)-456-7890', '(123).456.7890', '123456.7890', '(123) 456.7890', '(123)456-7890', '123 456 7890 x123', '123.456.7890x123', '123-456-7890ext123', '(123) 456 7890 ext123', '(123)4567890 x 123', '(123)-456-7890 ext 123', '(123).456.7890x123', '123456.7890x123', '(123) 456.7890x123', '(123)456-7890']
      // eslint-disable-next-line max-len
      const phoneRegex = /^\s*(?:\+?(\d{1,3}))?([-.( ]*(\d{3})[-.) ]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/
      return phoneRegex.test(phone)
    },

    isValidPrimaryRate (rate) {
      if (!rate) return false
      const blacklisted = validator.blacklist(rate, ',')
      return validator.isFloat(blacklisted, { gt: 0, lt: 21000000 })
    },

    isValidProcessOfficeId (id) {
      if (!id) return false
      return id === '1' || id === '2'
    },

    isValidQuery (query) {
      if (!query) return false
      return validator.isAlphanumeric(query)
    },

    isValidRenewalDate (date) {
      if (!date) return true
      return moment(date).isValid()
    },

    isValidRole (role) {
      if (!role) return false
      const roles = ['Account Manager', 'Underwriter', 'Collections Specialist', 'Processor', 'Salesperson', 'Master']
      return roles.includes(role)
    },

    isValidRoutingNumber (number) {
      if (!number) return false
      return validator.isInt(number) && (number).toString().length === 9
    },

    isValidShortenedName (name) {
      if (!name) return false
      // Currently, shortened name is being checked the same as client legal name
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
      const blacklisted = validator.blacklist(name, this.regex.shortenedName)
      return validator.isAlphanumeric(blacklisted)
    },

    isValidStartDate (startDate) {
      if (!startDate) return false
      return moment(startDate).isBefore(moment())
    },

    isValidState (state) {
      if (!state) return false
      const blacklisted = validator.blacklist(state, ' ')
      return validator.isAlpha(blacklisted)
    },

    isValidToPage (toPage, pages) {
      if (!toPage) return false
      return validator.isNumeric(toPage)
        && toPage <= pages
        && toPage > 0
    },

    isValidTransferDate (date) {
      if (!date) return false
      return moment(date).isValid() && moment(date).isBefore(moment())
    },

    isValidTruckNumber (number) {
      if (!number) return false
      return validator.isInt(number, { min: 0 })
    },

    isValidUploadDebtorsDownloadStartDate (startDate, endDate) {
      if (!startDate || !endDate) return false
      return moment(startDate).isSameOrBefore(endDate)
    },

    isValidURL (url) {
      if (!url) return false
      return validator.isURL(url)
    },

    isValidYearStarted (year) {
      if (!year) return false
      return parseInt(year, 10) <= moment().format('YYYY')
    },

    isValidZip (zip) {
      if (!zip) return false
      return validator.isPostalCode(zip, 'US')
    }
  }
}
