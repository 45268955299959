import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Invoice = new Entity('invoices')

// overwrite the Entity's get f()
Invoice.get = async (id) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/${id}`, {
    params: {
      include: 'all',
    }
  })
}

Invoice.getBatchReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/batch-report`, {
    params
  })
}

Invoice.getBuyoutJournalEntries = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/buyout-journal-entries`, {
    params
  })
}

Invoice.getClientAccountSummaryReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/client-account-summary`, {
    params
  })
}

Invoice.getClientAgingGeneralReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/client-aging-general`, {
    params
  })
}

Invoice.getDebtorAgingGeneralReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/debtor-aging-general`, {
    params
  })
}

Invoice.getDebtorPaymentReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/debtor-payment-report`, {
    params
  })
}

Invoice.getDetailedAgingReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/detailed-aging`, {
    params
  })
}

Invoice.getInvoiceReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/invoice-report`, {
    params
  })
}

Invoice.getLoanTapeReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/loan-tape`, {
    params
  })
}

// params: start date, end date (date object)
// output: total_approved_accounts_receivable_amount, count, clients
Invoice.getNewClientsVolumeAggregate = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/volume-new-clients-aggregate`, {
    params
  })
}

Invoice.getPortfolioReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/portfolio-report`, {
    params
  })
}

Invoice.getPossibleDuplicates = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/possible-duplicates`, {
    params
  })
}

Invoice.getRollForwardReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/roll-forward`, {
    params
  })
}

Invoice.getNetFundsEmployed = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/net-funds-employed`, {
    params
  })
}

Invoice.getUpdateStats = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/update-statistics`, {
    params
  });
}

// params: start date, end date (pass a date object)
// ouput: total_approved_accounts_receivable_amount
Invoice.getVolumeAggregate = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/volume-aggregate`, {
    params
  })
}

Invoice.getVolumeReport = async (params) => {
  return (await bobtailAPI()).get(`${Invoice.baseUrl}/volume-report`, {
    params
  });
}

Invoice.postBuyoutJournalEntries = async (params) => {
  return (await bobtailAPI()).post(`${Invoice.baseUrl}/buyout-journal-entries`, {
    ...params
  })
}


export default Invoice