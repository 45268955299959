import Entity from './entity'
import bobtailAPI from '../axiosConfig'

const Tenant = new Entity('tenants')

Tenant.fetchMaintenanceMode = async () => {
  return (await bobtailAPI()).get(`${Tenant.baseUrl}/fetch-maintenance-mode`)
}

Tenant.updateMaintenanceMode = async (params) => {
  return (await bobtailAPI()).put(`${Tenant.baseUrl}/update-maintenance-mode`, {
    ...params
  })
}

export default Tenant
