var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseInput" },
    [
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "BaseInput__label uppercase",
              attrs: { for: _vm.labelFor }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _c("input", {
        class: [
          "BaseInput__input fs-14",
          { "BaseInput__input--error": !_vm.valid }
        ],
        attrs: {
          autocomplete: "off",
          "data-cy": "base-input-input",
          disabled: _vm.disabled,
          id: _vm.labelFor,
          maxlength: _vm.maxLength,
          minlength: _vm.minLength,
          placeholder: _vm.placeholder,
          type: _vm.dataType
        },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event.target.value)
          },
          keydown: function($event) {
            return _vm.$emit("keydown", $event)
          }
        }
      }),
      _vm.type === "password"
        ? _c("i", {
            staticClass: "fa fa-view fa--none fc-light clickable",
            on: { click: _vm.toggleInputType }
          })
        : _vm._e(),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        !_vm.valid
          ? _c(
              "label",
              {
                staticClass: "fc-red fs-12",
                attrs: { "data-cy": "base-input-instructions" }
              },
              [_vm._v("\n      " + _vm._s(_vm.instructions) + "\n    ")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }