import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Client = new Entity('clients')

Client.createAllQuickbooksClients = async (params) => {
  return (await bobtailAPI()).post(`${Client.baseUrl}/quickbooks-create-all`, {
    params
  })
}

Client.createQuickbooksClients = async (params) => {
  return (await bobtailAPI()).post(`${Client.baseUrl}/quickbooks-clients`, {
    ...params
  })
}

Client.createQuickbooksCustomer = async (params) => {
  return (await bobtailAPI()).post(`${Client.baseUrl}/quickbooks-create/${params.id}`, {
    params
  })
}

Client.findQuickbooks = async (params) => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/quickbooks/find`, {
    params
  })
}

Client.getACH = async () => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/ach-clients`)
}

Client.getChangedBalances = async () => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/changed-balances`)
}

Client.getChargeBacksInRange = async (params) => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/chargebacks/${params.id}`, {
    params
  })
}

Client.getLastChargeBack = async (id) => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/chargeback/${id}`)
}

Client.getQuickbooksClients = async (params) => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/quickbooks-clients`, {
    params
  })
}

// params: start date, end date (date object)
// output: count, clients
Client.getRegisteredClients = async (params) => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/registered-clients`, {
    params
  })
}

Client.getWires = async () => {
  return (await bobtailAPI()).get(`${Client.baseUrl}/wire-clients`)
}

Client.postMessage = async (params) => {
  return (await bobtailAPI()).post(`${Client.baseUrl}/message-contacts/${params.id}`, {
    ...params
  })
}

Client.resetPassword = async (params) => {
  return (await bobtailAPI()).post(`${Client.baseUrl}/send-invitation/${params.id}`, {
    params
  })
}

export default Client