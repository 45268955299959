<template>
  <div
    class="BaseSelectSearch"
    id="Testing__BaseSelectSearch"
  >
    <!-- Predefined Search  -->
    <v-select
      v-if="options.length > 1"
      v-model="inputType"
      @input="query.value = ''"
      aria-label="Universal search selector with input"
      class="BaseSelectSearch__selector"
      data-cy="base-select-search-select-input"
      :clearable="false"
      :options="options"
    />
    <base-client-search
      v-if="inputType.toLowerCase() === 'clients'"
      @selected="goToClientActivity"
      class="BaseSelectSearch__client-input"
      :reset-after-selected="true"
    />
    <base-debtor-search
      v-if="inputType.toLowerCase() === 'debtors'"
      @selected="goToDebtorProfile"
      class="BaseSelectSearch__debtor-input"
      :reset-after-selected="true"
    />
    <base-input
      v-if="['load #', 'invoice #'].includes(inputType.toLowerCase())"
      v-model.trim="query.value"
      @keydown.enter.stop="searchQuery"
      class="BaseSelectSearch__input"
      data-cy="base-select-search-query-input"
      :instructions="instructions.QUERY_INSTRUCTIONS"
      :placeholder="`Search by ${inputType.toLowerCase() === 'load #' ? 'load #' : 'invoice #'}`"
      :valid="query.valid"
    />
    <base-button
      @click="searchQuery"
      class="BaseSelectSearch__search-btn bg-blue fc-white"
      data-cy="base-select-search-search-btn"
      :disabled="!['load #', 'invoice #'].includes(inputType.toLowerCase()) || !query.value"
    >
      Search
    </base-button>
  </div>
</template>

<script>
// Helpers
import DOMPurify from 'dompurify'
import isEqual from 'lodash.isequal'
import {
  Invoice,
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseClientSearch from './base-client-search.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'BaseSelectSearch',

  components: {
    BaseButton,
    BaseClientSearch,
    BaseDebtorSearch,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    // Used to set the available selector options
    // May be null on load (no active user), but will be updated by App.vue on login
    user: {
      type: Object,
      required: false,
      default: null,
    }
  },

  created () {
    if (this.user) {
      // Init routes
      this.setSelectorOptions()
    }
    else {
      this.visible = false
    }
    this.setQueryFromQueryString()
  },

  data () {
    return {
      inputType: 'clients',
      query: {
        valid: true,
        value: '',
      },
      options: null,
      visible: true,
    }
  },

  watch: {
    $route (newRoute, oldRoute) {
      if (newRoute !== oldRoute) this.setQueryFromQueryString()
    },

    user () {
      this.setSelectorOptions()
    }
  },

  methods: {
    goToClientActivity (client) {
      // TODO: why would param ever be null???
      // Protection from an odd `null` value from the client/debtor select component
      // eslint-disable-next-line max-len
      // https://sentry.io/organizations/bobtail/issues/1863511331/events/3e88d115b7834d3681fa16d2cb527e22/?environment=production&project=5223295&query=is%3Aunresolved&statsPeriod=7d
      if (!client) return
      // Check for navigation duplication
      if (this.$route.name.includes('client') && this.$route.params.id === client.id.toString()) {
        return
      }
      this.$router.push({
        name: 'client',
        params: {
          id: client.id.toString(),
        },
      })
    },

    goToDebtorProfile (debtor) {
      // TODO: why would param ever be null???
      // Protection from an odd `null` value from the client/debtor select component
      // eslint-disable-next-line max-len
      // https://sentry.io/organizations/bobtail/issues/1863511331/events/3e88d115b7834d3681fa16d2cb527e22/?environment=production&project=5223295&query=is%3Aunresolved&statsPeriod=7d
      if (!debtor) return
      // Check for navigation duplication
      if (this.$route.name.includes('debtor') && this.$route.params.id === debtor.id.toString()) {
        return
      }
      this.$router.push({
        name: 'debtor',
        params: {
          id: debtor.id.toString(),
        },
      })
    },

    resetData () {
      this.$set(this.query, 'valid', true)
      this.$set(this.query, 'value', '')
    },

    async searchQuery () {
      if (!this.query.value) return
      if (!this.validation()) return
      this.progressStart()
      try {
        // Get invoices with the specific IDs, if not null
        // Get invoices with load #/ID (soft search) (null will just exclude the property from search)
        const results = (await Invoice.queryList({
          display_id: this.inputType.toLowerCase() === 'invoice #'
            ? this.query.value
            : null,
          forceAllInvoiceIds: true, // Returns the IDs of the each invoice in the results
          like___load_number: this.inputType.toLowerCase() === 'load #'
            ? DOMPurify.sanitize(this.query.value)
            : null,
        }, 1000)).data

        this.$store.commit('STORE_UNIVERSAL_SEARCH_RESULTS', results)
        // Clear 'search' information so information pre-existing from a past search doesn't
        // confuse getNextInvoicePosition
        window.localStorage.removeItem('search')
        // To avoid NavigationDuplicated, update the queryParams if the associated query value
        // we are looking for is different than the current value in the URL.
        // I.E. If you search for load '1234' and then '1234' again, it will not update and reroute
        // because the current query param for load is already '1234'
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1660
        if (
          (this.inputType === 'load #' && (this.query.value !== this.$route.query.loadNumber))
          // when searcing for IDs, the query variable for IDs is an array, so we must use a compare function
          || (this.inputType === 'invoice #' && !isEqual([this.query.value], this.$route.query.ids))
        ) {
          const queryParams = {}
          if (this.inputType.toLowerCase() === 'load #') {
            queryParams.loadNumber = DOMPurify.sanitize(this.query.value)
          }
          if (this.inputType.toLowerCase() === 'invoice #') {
            queryParams.display_id = [DOMPurify.sanitize(this.query.value)]
          }
          this.$router.push({
            query: { ...queryParams },
            name: 'search-results',
          })
        }
        this.progressFinish()
        this.resetData()
      }
      catch (error) {
        this.captureSentryEvent(
          'Employee Selector "Query Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },

    setQueryFromQueryString () {
      // Do NOT autofill the input when a query (load or ID) variable exists, because we want
      // the select input to erase after each search
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1660
      if (this.$route.query.loadNumber) {
        this.inputType = 'load #'
      }
      if (this.$route.query.ids) {
        this.inputType = 'invoice #'
      }
    },

    setSelectorOptions () {
      if (this.user.client) this.options = ['load #']
      else this.options = ['clients', 'debtors', 'load #', 'invoice #']
      // Default the selector to the first option (regardless of user)
      this.inputType = this.options[0]
    },

    validation () {
      let valid = true

      if (this.query.value && !this.isValidQuery(this.query.value)) {
        this.$set(this.query, 'valid', false)
        valid = false
      } else {
        this.$set(this.query, 'valid', true)
      }

      return valid
    }
  },
}
</script>

<style lang="sass">
.BaseSelectSearch
  +flex(row, $align: center)

  &__client-input,
  &__debtor-input
    .BaseUserSearch__input
      border-radius: 0

  &__client-input.BaseUserSearch,
  &__debtor-input.BaseUserSearch,
  &__input .BaseInput__input
    width: rem(350px)

  &__input
    .BaseInput__input
      border-radius: 0

  &__search-btn
    border-radius: 0 $border-radius $border-radius 0

  &__selector
    // flex-shrink keeps the fucking selector from changing widths when open
    // which is dumb
    flex-shrink: 0
    width: rem(140px)

    .vs__dropdown-toggle
      background-color: $borders
      border-radius: $border-radius 0 0 $border-radius
      border-right: 0

    .vs__dropdown-menu
      min-width: 0
      width: rem(141px)

    .vs__open-indicator
      color: $text-dark

    .vs__selected
      color: $text-dark


</style>