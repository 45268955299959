import bobtailAPI from '../axiosConfig'

export default class Users {
  static baseUrl = '/api/users'

  static async canResetPassword (email) {
    return (await bobtailAPI()).post(`${Users.baseUrl}/can-reset-password`, {
      email
    })
  }

  static async getClientData (params) {
    return (await bobtailAPI()).post(`${Users.baseUrl}/assumeClientControl`, {
      ...params
    })
  }
}