<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
// Packages
// import Toastr from 'toastr'

export default {
  name: 'ToastrAlert',
}
</script>

<style lang="sass">
@import 'toastr/toastr.scss'

#toast-container

  >
    div
      opacity: 1 !important
      padding: rem(16px)

    .toast
      background-image: none !important

      .toast-title,
      .toast-message
        margin-left: rem(40px)

    .toast:before
      font-family: BobtailIcons
      font-size: rem(12px)
      line-height: rem(16px)
      padding: rem(1px) // makes the icon full
      position: fixed

    .toast-error:before
      color: $white
      content: '\f00c'
    .toast-warning:before
      color: $white
      content: '\f012'
    .toast-info:before
      color: $white
      content: '\f012'
    .toast-success:before
      color: $white
      content: '\f003'

    .toast-message,
    .toast-title
      color: $white
</style>