// Packages
import toastr from 'toastr'
// Components
import ToastrAlert from './toastr-alert.vue'

// Options = (in use) Vue.use(ToastrAlert, {... these are options which override DEFAULT_OPTIONS})
const install = (Vue, customToastrOptions = {}) => {
  const DEFAULT_OPTIONS = {
    // Toastr's default options based on: https://codeseven.github.io/toastr/demo.html
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-top-full-width',
    preventDuplicates: true,
    onclick: null,
    showDuration: '500',
    hideDuration: '1000',
    timeOut: '4000', // How long it lasts w/o any interaction
    extendedTimeOut: '1000', // How long it lasts if you hover over it
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  }

  const ourToastrOptions = Object.assign(DEFAULT_OPTIONS, customToastrOptions)

  const Toastr = {
    $vm: null,
    state: {},
    init (vm) {
      this.$vm = vm
    },

    // Type of Alert functions
    error (message, title, options = {}) {
      const updatedOptions = Object.assign(ourToastrOptions, options)
      toastr.error(message, title, updatedOptions)
    },
    info (message, title, options = {}) {
      const updatedOptions = Object.assign(ourToastrOptions, options)
      toastr.info(message, title, updatedOptions)
    },
    success (message, title, options = {}) {
      const updatedOptions = Object.assign(ourToastrOptions, options)
      toastr.success(message, title, updatedOptions)
    },
    warning (message, title, options = {}) {
      const updatedOptions = Object.assign(ourToastrOptions, options)
      toastr.warning(message, title, updatedOptions)
    },

    // Functionality functions
    closeAll () {
      toastr.clear()
    },
    closeCurrent () {
      toastr.remove()
    }
  }

  Vue.component('toastr-alert', ToastrAlert)
  Vue.prototype.$Toastr = Toastr
}

export default install
