<template>
  <button
    @click="$emit('click', $event)"
    :aria-disabled="disabled"
    :disabled="disabled"
    class="BaseButton"
  >
    <div
      class="BaseButton__inner fs-14 no-wrap"
      tabindex="-1"
    >
      <slot />
    </div>
  </button>
</template>

<script>
/*
 * This has a copy in /marketing-website/components/base-button.vue
 * If you alter this, please alter it copy
*/

export default {
  name: 'BaseButton',

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass">
/*
 * Styles:
 *
 */
.BaseButton
  $block: &
  $box-shadow-stateless: 0 rem(1px) rem(1px) 0 rgba(#131F15, .1)
  $blue-btn-border-color: #2643A6
  $blue-btn-hover-bg-color: #2D4FC1
  $blue-btn-active-bg-color: #223E9C
  $bg-color-btn-inset-shadow: inset 0 rem(2px) 0 0 rgba(#fff, .06)
  $green-btn-border-color: #2D9C3C
  $green-btn-hover-bg-color: #2D9C3D
  $green-btn-active-bg-color: #228430
  $red-btn-border-color: #CC4128
  $red-btn-hover-bg-color: #B63C25
  $red-btn-active-bg-color: darken(#E6492D, 10%)
  border-radius: $border-radius
  cursor: pointer
  height: $input-height
  outline: none
  max-height: $input-height
  padding: rem(2px)
  text-transform: capitalize
  user-select: none

  &::-moz-focus-inner
    border: 0

  &[disabled],
  &[aria-disabled]
    box-shadow: none !important
    cursor: default !important
    opacity: 0.6 !important

    &[class*="bg-blue"]
      background-color: $branding !important

    &[class*="bg-green"]
      background-color: $success !important

    &[class*="bg-red"]
      background-color: $danger !important

    &[class*="bg-white"]
      background-color: $white !important

    .fa
      color: $icon !important

  &[class*="bg-blue"]
    border: rem(1px) solid $blue-btn-border-color
    box-shadow: $box-shadow-stateless, $bg-color-btn-inset-shadow

    .fa
      color: $white

  &[class*="bg-green"]
    border: rem(1px) solid $green-btn-border-color
    box-shadow: $box-shadow-stateless, $bg-color-btn-inset-shadow

    .fa
      color: $white

  &[class*="bg-red"]
    border: rem(1px) solid $red-btn-border-color
    box-shadow: $box-shadow-stateless, $bg-color-btn-inset-shadow

    .fa
      color: $white

  &[class*="bg-white"]
    border: $border

  &:hover
    box-shadow: $button-shadow-hover

    &[class*="bg-blue"]
      background-color: $blue-btn-hover-bg-color

    &[class*="bg-green"]
      background-color: $green-btn-hover-bg-color

    &[class*="bg-red"]
      background-color: $red-btn-hover-bg-color

    &[class*="bg-white"]
      .fa
        color: $branding

  &:focus

    &[class*="bg-blue"]
      #{$block}__inner
        border-color: rgba(255, 255, 255, 0.6)

    &[class*="bg-green"]
      #{$block}__inner
        border-color: rgba(255, 255, 255, 0.6)

    &[class*="bg-light"]
      #{$block}__inner
        border-color: rgba(255, 255, 255, 0.6)

    &[class*="bg-red"]
      #{$block}__inner
        border-color: rgba(255, 255, 255, 0.6)

    &[class*="bg-white"],
    &[class*="bg-trans"]
      border-color: $branding
      #{$block}__inner
        border-color: rgba(191, 206, 255, 0.6)

      .fa
        color: $branding

  &:active

    &[class*="bg-blue"]
      background-color: $blue-btn-active-bg-color

    &[class*="bg-green"]
      background-color: $green-btn-active-bg-color

    &[class*="bg-red"]
      background-color: $red-btn-active-bg-color

    &[class*="bg-white"]
      box-shadow: $button-shadow-active

      .fa
        color: $branding

  .fa
    color: $icon
    font-size: rem(20px)

  &__inner
    +flex($align: center, $justify: center)
    border: rem(1.5px) solid transparent
    border-radius: rem(2px)
    color: inherit
    height: 100%
    outline: none
    padding: 0 rem(11px) // 13 - the side padding from the button element

</style>
