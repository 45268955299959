<template>
  <div class="BaseAlert">
    <div class="BaseAlert__content row row--align-center">
      <div
        :class="['row row--align-center', {
          'row--width-auto': !disableClose
        }]"
      >
        <slot name="message" />
        <slot name="actions" />
      </div>

      <div
        v-if="!disableClose"
        class="BaseAlert__close-btn-container row row--width-auto"
      >
        <button
          @click="$emit('close-alert')"
          class="BaseAlert__close-icon-btn"
        >
          <i class="fa fa-add fa-rotate-45 fa--none" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseAlert',

  props: {
    // Pass true if you want to use label as just a text-based alert
    // prevents user from closing/hiding alert
    disableClose: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="sass">
.BaseAlert
  background-color: $danger
  width: 100%

  .fa-add
    border: rem(1px) solid $white
    border-radius: 50%
    color: $white
    padding: rem(2px)

  &__close-btn-container
    margin-left: auto
    // Align icon with right side of application when at a small desktop width
    margin-right: rem(-8px)

  &__close-icon-btn
    padding: rem(8px)

  &__content
    margin: 0 auto
    max-width: rem(1440px)
    padding: rem(4px) 1rem
    width: 100%
</style>