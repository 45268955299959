const getCognitoInfo = () => {
  let clientId = '497aj01lgcpciuk5g3j0j65dvl'
  let userPoolId = 'us-east-1_eVKZDcNUA'
  if (process.env.VUE_APP_ENV === 'test') {
    clientId = '4i50pths5g9kha0lij9u9vi6js'
    userPoolId = 'us-east-1_E2Apl1F4x'
  }
  if (process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal') {
    clientId = '2gdajqckt7bt111co5m5s536ha'
    userPoolId = 'us-east-1_s3J4Te3zv'
  }
  return {
    UserPoolId: userPoolId,
    ClientId: clientId,
  }
}

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Floria',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Main',
  'Maryland',
  'Massachussetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
]

export const statesAbbreviations = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

export default getCognitoInfo