// Packages
import Vue from 'vue'
import moment from 'moment'
import VueProgressBar from 'vue-progressbar'
import vSelect from 'vue-select'
import VueAnalytics from 'vue-analytics'
// Helpers
import router from './router'
import store from './store/index'
// Mixin
import Alerter from './utils/alertMixin'
import consoleMixin from './utils/consoleMixin'
import SentryMixin from './utils/sentryMixin'
// Components
import App from './App.vue'
import ToastrAlert from './components/toastr-alert'
// Directives
import ClickOutside from './utils/click-outside'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('i', { class: 'fa fa-x' }),
  },
  OpenIndicator: {
    render: createElement => createElement('i', { class: 'fa fa-select-arrows' }),
  },
})

Vue.component('v-select', vSelect)
Vue.directive('click-outside', ClickOutside)
Vue.mixin(Alerter)
Vue.mixin(consoleMixin)
Vue.mixin(SentryMixin)
Vue.use(ToastrAlert)
Vue.use(VueProgressBar, {
  autoFinish: false,
  color: '#61AC87',
  failedColor: '#CC5A5A',
  thickness: '5px'
})
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  router
})

Vue.config.productionTip = false

Vue.filter('datetime', (datetime, format = 'MM/DD/YYYY') => {
  if (!datetime) return null
  return moment(datetime).format(format)
})

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app
