var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "BaseSelectSearch",
      attrs: { id: "Testing__BaseSelectSearch" }
    },
    [
      _vm.options.length > 1
        ? _c("v-select", {
            staticClass: "BaseSelectSearch__selector",
            attrs: {
              "aria-label": "Universal search selector with input",
              "data-cy": "base-select-search-select-input",
              clearable: false,
              options: _vm.options
            },
            on: {
              input: function($event) {
                _vm.query.value = ""
              }
            },
            model: {
              value: _vm.inputType,
              callback: function($$v) {
                _vm.inputType = $$v
              },
              expression: "inputType"
            }
          })
        : _vm._e(),
      _vm.inputType.toLowerCase() === "clients"
        ? _c("base-client-search", {
            staticClass: "BaseSelectSearch__client-input",
            attrs: { "reset-after-selected": true },
            on: { selected: _vm.goToClientActivity }
          })
        : _vm._e(),
      _vm.inputType.toLowerCase() === "debtors"
        ? _c("base-debtor-search", {
            staticClass: "BaseSelectSearch__debtor-input",
            attrs: { "reset-after-selected": true },
            on: { selected: _vm.goToDebtorProfile }
          })
        : _vm._e(),
      ["load #", "invoice #"].includes(_vm.inputType.toLowerCase())
        ? _c("base-input", {
            staticClass: "BaseSelectSearch__input",
            attrs: {
              "data-cy": "base-select-search-query-input",
              instructions: _vm.instructions.QUERY_INSTRUCTIONS,
              placeholder:
                "Search by " +
                (_vm.inputType.toLowerCase() === "load #"
                  ? "load #"
                  : "invoice #"),
              valid: _vm.query.valid
            },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.stopPropagation()
                return _vm.searchQuery($event)
              }
            },
            model: {
              value: _vm.query.value,
              callback: function($$v) {
                _vm.$set(
                  _vm.query,
                  "value",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "query.value"
            }
          })
        : _vm._e(),
      _c(
        "base-button",
        {
          staticClass: "BaseSelectSearch__search-btn bg-blue fc-white",
          attrs: {
            "data-cy": "base-select-search-search-btn",
            disabled:
              !["load #", "invoice #"].includes(_vm.inputType.toLowerCase()) ||
              !_vm.query.value
          },
          on: { click: _vm.searchQuery }
        },
        [_vm._v("\n    Search\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }