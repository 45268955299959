// Packages
import { CognitoUserPool } from 'amazon-cognito-identity-js'
// Helpers
import getCognitoInfo from './constants'

const getCurrentCognitoUser = () => {
  // https://www.npmjs.com/package/amazon-cognito-identity-js
  // ^ Look at "use case 16"
  const { ClientId, UserPoolId } = getCognitoInfo()
  const poolData = {
    ClientId,
    UserPoolId,
  }
  const userPool = new CognitoUserPool(poolData)
  const cognitoUser = userPool.getCurrentUser()
  return cognitoUser
}

export const globalSignOut = () => {
  const cognitoUser = getCurrentCognitoUser()
  if (cognitoUser === null) {
    return
  }
  cognitoUser.signOut()
  localStorage.removeItem('user', null)
  localStorage.removeItem('master', null)
  localStorage.removeItem('controlledClient', null)
  return true
}

export const getCurrentJWTToken = async () => {
  const cognitoUser = getCurrentCognitoUser()
  /* cognitoUser:
     * Session: null,
     * client: { enpoint, userAgent },
     * pool: { clientId, storage, userPoolId },
     * signInUserSession: { accessToken, idToken, refreshToken },
     * storage: { LastAuthUser, accessToken, idToken, refreshToken },
     * userDataKey,
     * username,
     */
  // returns jwtToken if user
  // const refreshToken = cognitoUser.getSession().getRefreshToken()

  if (cognitoUser !== null) {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, res) => {
        if (res === null) {
          console.error('There was an issue getting the current cognito user\'s session. The response is null.')
          globalSignOut()
          window.open('/login', '_self')
          // return as we don't want to process token in case of failure
          return reject(err)
        }
        if (err) {
          console.error('Error getting current cognito user', err)
          // IDC if the promise rejects, if there is an issue, route back to login.
          // This err happens when a user hasn't signed in or refreshed in days/weeks
          globalSignOut()
          window.open('/login', '_self')
          // return as we don't want to process token in case of failure
          return reject(err)
        }
        const token = res.getAccessToken().getJwtToken()
        resolve(token)
      })
    })
  }
  // Returns null if no user
  return Promise.resolve(cognitoUser)
}
