<template>
  <div
    v-if="currentRoute"
    class="NavMobile width-100"
    data-cy="nav-mobile"
  >
    <!-- MOBILE MENU HEADER (DISPLAYED WHEN MENU IS NOT) -->
    <div class="NavMobile__header bg-blue row row--align-center row--justify-center">
      <button
        @click="goToMobileHome"
        aria-label="Mobile menu button"
        class="NavMobile__home-btn"
        id="Testing__NavMobileMenuBtn"
      >
        <i class="fa fa-house fa--none fc-white" />
      </button>
      <h1
        class="NavMobile__header-lbl fc-white fs-22 fw-med capitalize"
        id="Testing__NavMobileHeader"
      >
        {{ currentRoute }}
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavMobile',

  computed: {
    currentRoute () {
      if (!this.$route.name) return null
      // Spit out X title based on this.$route.name
      // return this.componentNavItems.find((item) => {
      //   this.CLog(item.to.name, this.$route.name)
      //   return item.to.name === this.$route.name
      // }).title
      const routeNames = {
        admin: 'admin',
        'client-profile': 'profile',
        'client-contacts': 'contacts',
        'client-activity': 'activity',
        'client-docs': 'docs',
        'client-assignments': 'assignments',
        'client-data-general': 'general data',
        'client-data-dilution': 'dilution data',
        'client-data-debtors': 'debtor data',
        'client-status': 'status',
        'create-client': 'create client',
        'create-debtor': 'create debtor',
        'create-employee': 'create employee',
        'credit-check': 'credit check',
        'dashboard-client-notifications': 'notifications',
        'dashboard-client-transfers': 'transfers',
        'dashboard-client-balance-history': 'balance history',
        'dashboard-client-banking': 'banking',
        'dashboard-employee-audit-log': 'audit log',
        'dashboard-employee-balances': 'bobtail balances',
        'dashboard-employee-quickbooks': 'bobtail quickbooks',
        'dashboard-employee-reports': 'bobtail reports',
        'dashboard-employee-collections': 'bobtail collections',
        'dashboard-employee-transfers': 'bobtail transfers',
        'debtor-profile': 'debtor profile',
        'debtor-contacts': 'debtor contacts',
        'debtor-credit': 'debtor credit',
        'debtor-assignments': 'debtor assignments',
        'debtor-data-general': 'debtor general data',
        'debtor-data-dilution': 'debtor dilution data',
        approved: 'approved',
        declined: 'declined',
        paid: 'paid',
        pending: 'pending',
        'invoice-details': 'invoice details',
        login: 'login',
        'log-out': 'log out',
        'password-reset': 'password reset',
        'permission-denied': 'permission denied',
        'set-password': 'set password',
        styleguide: 'styleguide',
        'submit-invoice': 'submit invoice',
        'user-invite': 'user invite',
        'user-management': 'user management',
        'page-not-found': 'page not found',
      }
      return routeNames[this.$route.name]
    },
  },

  methods: {
    goToMobileHome () {
      this.$router
        .push({ name: 'home-mobile' })
        .catch((error) => {
          this.CError(error)
          this.captureSentryEvent(
            'home-mobile redirect error"',
            {
              config: error.config,
              details: error,
              response: error.response,
            }
          )
        });
    },
  },
}
</script>

<style lang="sass" scoped>
.NavMobile
  $block: &

  .fa
    font-size: rem(20px)
    width: rem(20px)

  &__header
    border-bottom: $border
    box-shadow: $shadow-c
    height: rem(60px)
    width: 100%
    // There's nothing greater than 4 besides base-modal
    z-index: 5

  &__home-btn
    left: 0
    padding: rem(16px)
    position: absolute
</style>
