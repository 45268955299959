import bobtailAPI from '../axiosConfig'
import Entity from './entity'

const Email = new Entity('emails')

Email.shareInvoice = async (params) => {
  return (await bobtailAPI()).post(`${Email.baseUrl}/share`, {
    ...params
  })
}

export default Email