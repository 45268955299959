var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "nav",
        {
          class: {
            Nav__desktop: !_vm.mobile.isMobile,
            Nav__mobile: _vm.mobile.isMobile
          }
        },
        [
          !_vm.mobile.isMobile
            ? _c("navigation-desktop", {
                attrs: {
                  "is-master": _vm.isMaster,
                  "nav-items": _vm.navItems,
                  "user-is-client": !!(_vm.user && _vm.user.client)
                }
              })
            : _c("navigation-mobile", { attrs: { "nav-items": _vm.navItems } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }